@import './colors.css';
@import './media.css';
@import './layout.css';
@import './typography.css';
@import '../../node_modules/dialog-polyfill/dialog-polyfill.css';

:root {
    color: var(--almostBlack);
    font-family: var(--font-Poppins);
    scroll-behavior: smooth;
}

body {
    margin: 0;
}

[id=root] {
    overflow: hidden;
}

*,
::after,
::before {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

.text-center {
    text-align: center;
}
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
