@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');


:root {
    --font-Poppins: 'Roboto Condensed', sans-serif;
    --h1-font-size: 52px;
    --weight-light: 300;
    --weight-regular: 400;
    --weight-medium: 500;
    --weight-semiBold: 600;
    --weight-bold: 700;
}

@media (--mq-width-1024) {
    :root {
        --h1-font-size: 40px;
    }
}

@media (--mq-width-720) {
    :root {
        --h1-font-size: 30px;
    }
}

@media (--mq-width-375) {
    :root {
        --h1-font-size: 24px;
    }
}
