@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
.AccordionButton {
    background: var(--cerulean);
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    width: 24px;
    height: 24px;
    border: none;
    font-size: 0;
    cursor: pointer;
    transition: background 0.4s;
 }
 .AccordionButton:hover {
    background: var(--bluish);
 }
 .AccordionButton::before,
 .AccordionButton::after {
    width: 13px;
    height: 2px;
    background: #fff;
    content: '';
    transition: transform 0.1s;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
 }
 .AccordionButton::after {
    transform: rotate(90deg);
 }
 .AccordionButtonOpen::after {
    transform: rotate(0deg);
 }



.AccordionTitle {
    font-size: 20px;
    font-weight: var(--weight-medium);
    color: var(--dark-grey);
    margin: 0;
    flex-basis: 100%;
    transition: color 0.1s;
    padding-right: 15px;
  }
  
  .AccordionTitle:hover {
    color: var(--bluish);
  }
  
  .AccordionTitleOpen {
    color: var(--cerulean);
  }


.AppNotification {
	--AppNotification-button-marginLeft: 1em;
	--AppNotification-display: flex;

	/* Ensures that the element fills the bottom space when it bounces in, without a gap below. */
	--AppNotification-rightOffset: 25vw;

	animation: bounce 0.3s forwards cubic-bezier(0.175, 0.885, 0.32, 1.275);
	background-color: var(--cerulean);
	color: white;
	font-family: inherit;
	font-size: 14px;
	font-weight: var(--weight-light);
	padding: 0.75em var(--global-padding);
	padding-right: calc(var(--global-padding) + var(--AppNotification-rightOffset));
	text-decoration: none;
	transform: translateX(var(--AppNotification-rightOffset));
	width: calc(100% + var(--AppNotification-rightOffset));
	opacity: 0;
	display: var(--AppNotification-display);
	justify-content: space-between;
	align-items: center;
}

@media (--mq-width-480) {
	.AppNotification {
		--AppNotification-button-marginLeft: 0;
		--AppNotification-display: block;
	}
}

.AppNotification-button {
	background: white;
	border: 0;
	color: var(--cerulean);
	cursor: pointer;
	font-family: inherit;
	font-size: 1.15em;
	font-weight: var(--weight-regular);
	margin: 1em 0;
	margin-left: var(--AppNotification-button-marginLeft);
	padding: .4em 1em;
	white-space: nowrap;
}

@keyframes bounce {
	0% {
		transform: translateX(var(--AppNotification-rightOffset));
		opacity: 0;
	}

	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

:root {
    --ArrowLink-fontSize: 16px;
}

@media (max-width: 1024px) {
    :root {
        --ArrowLink-fontSize: 14px;
    }
}

.ArrowLink {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: var(--ArrowLink-fontSize);
    padding: 0
}

.ArrowLink span {
        color: var(--cerulean);
        text-decoration: none;
        display: inline-block
    }

.ArrowLink span::before {
            background-image: url(/static/media/icon-arrow.5f732fd5.svg);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 3.625;
            content: '';
            display: inline-block;
            height: 1em;
            margin: 0 1.15em 0 0;
            transform: translateY(3px);
            width: 60px;
        }

.blueHeading {
  font-size: clamp(18px, 5vw, 36px);
  padding: 1.2rem;
  color: #ffffff;
  text-align: center;
  margin: 0;
  background: #0090d3;
}

:root {
    --Button-fontSize: inherit;
    --Button-padding: 1.25em 1.5em 1.3125em;
    --Button-arrow-next-width: 350px;
}

@media (max-width: 1024px) {
    :root {
        --Button-fontSize: 14px;
        --Button-padding: 1.1em 1.5em 1em;
    }
}

@media (max-width: 720px) {
    :root {
        --Button-arrow-next-width: 240px;
    }
}

.Button {
    background-color: var(--cerulean);
    border: 0;
    color: var(--white);
    cursor: pointer;
    font-family: inherit;
    font-size: var(--Button-fontSize);
    font-weight: var(--weight-bold);
    letter-spacing: .05em;
    padding: var(--Button-padding);
    text-decoration: none;
    width: 100%;
    transition: background .4s
}

.Button:hover {
        background-color: var(--aqua-blue);
    }

.Button:disabled {
        background-color: var(--aqua-blue);
    }

.Button-link {
    display: block;
    text-align: center;
}

.Button-arrow-next {
    width: var(--Button-arrow-next-width);
    font-weight: var(--weight-bold)
}

.Button-arrow-next span::before {
        background-image: url(/static/media/icon-arrow-white.4135fbeb.svg);
        background-position: center center;
        background-repeat: no-repeat;
        content: '';
        display: inline-block;
        height: 1em;
        margin: 0 2em 0 -0.5em;
        transform: rotateZ(180deg) translateY(-2px);
        width: 60px;
    }

.Checkbox {
  position: relative
}
.Checkbox > input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    top: 0;
    left: 0
  }
.Checkbox > input:checked + i {
      background: var(--cerulean);
      border-color: var(--cerulean)
    }
.Checkbox > input:checked + i::before {
        display: block;
      }
.Checkbox > input:focus + i {
      outline: 1px solid var(--bluish);
    }
.Checkbox > input + i {
      display: block;
      width: 20px;
      height: 20px;
      background: #ffffff;
      border: 1px solid var(--brownish-grey-two);
      border-radius: 2px;
      position: relative
    }
.Checkbox > input + i::before {
        content: '';
        position: absolute;
        width: 10px;
        height: 5px;
        border-left: 1.5px solid #fff;
        border-bottom: 1.5px solid #fff;
        transform: rotate(-45deg);
        top: 5px;
        left: 5px;
        display: none;
      }

.Column {
    padding-left: 20px;
    padding-right: 20px;
}

.Dialog {
    animation:
        0.3s ease 0.1s 1 forwards Dialog-fadeIn, 0.4s ease-out 1 Dialog-zoomIn;
    border: 0;
    bottom: 0;
    max-height: 80%;
    max-width: 720px;
    opacity: 0;
    overflow-y: auto;
    padding: 0;
    position: fixed;
    top: 0;
    transform-origin: 50% 100%
}

.Dialog::backdrop {
        animation: 0.3s ease-in-out 1 Dialog-fadeIn;

        /* CSS variables don't seem to work here */
        background-color: rgba(17, 17, 17, .75);
    }

@keyframes Dialog-fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes Dialog-zoomIn {
    0% {
        transform: scale(.95);
    }

    100% {
        transform: scale(1);
    }
}


:root {
    --DivAsButton-fontSize: inherit;
    --DivAsButton-padding: 1.25em 1.5em 1.3125em;
}

@media (max-width: 1024px) {
    :root {
        --DivAsButton-fontSize: 14px;
        --DivAsButton-padding: 1.1em 1.5em 1em;
    }
}

.DivAsButton {
    background-color: var(--cerulean);
    border: 0;
    color: var(--white);
    cursor: pointer;
    font-family: inherit;
    font-size: var(--DivAsButton-fontSize);
    font-weight: var(--weight-bold);
    letter-spacing: .05em;
    padding: var(--DivAsButton-padding);
    text-decoration: none;
    width: 100%;
    transition: background .4s
}

.DivAsButton:hover {
        background-color: var(--aqua-blue);
    }

.DivAsButton:disabled {
        background-color: var(--aqua-blue);
    }


:root {
    --DocBrowserLogo-cube-width: 30px;
    --DocBrowserLogo-cube-width-half: 15px;
    --DocBrowserLogo-max-width: inherit;
    --DocBrowserLogo-height: 34px;
}

@media (max-width: 720px) {
    :root {
        --DocBrowserLogo-max-width: 200px;
        --DocBrowserLogo-height: 100%;
    }
}

.DocBrowserLogo {
    height: var(--DocBrowserLogo-height);
    display: flex;
    max-width: var(--DocBrowserLogo-max-width)
}

.DocBrowserLogo > svg {
        fill: var(--cerulean);
        height: 100%;
        max-width: 100%;
        transition: fill .25s ease;
    }

.DocBrowserLogo.white > svg {
        fill: white;
    }

.DocBrowserLogo > img {
        fill: var(--cerulean);
        max-width: 100%;
        transition: fill .25s ease;
    }

.DocBrowserLogo.white > img {
        fill: white;
    }

@-webkit-keyframes spincube {
    from,
    to { -webkit-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg); }
    16% { -webkit-transform: rotateY(-90deg); }
    33% { -webkit-transform: rotateY(-90deg) rotateZ(90deg); }
    50% { -webkit-transform: rotateY(-180deg) rotateZ(90deg); }
    66% { -webkit-transform: rotateY(-270deg) rotateX(90deg); }
    83% { -webkit-transform: rotateX(90deg); }
}

@keyframes spincube {
    from,
    to {
        -moz-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
        -ms-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
        transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }

    16% {
        -moz-transform: rotateY(-90deg);
        -ms-transform: rotateY(-90deg);
        transform: rotateY(-90deg);
    }

    33% {
        -moz-transform: rotateY(-90deg) rotateZ(90deg);
        -ms-transform: rotateY(-90deg) rotateZ(90deg);
        transform: rotateY(-90deg) rotateZ(90deg);
    }

    50% {
        -moz-transform: rotateY(-180deg) rotateZ(90deg);
        -ms-transform: rotateY(-180deg) rotateZ(90deg);
        transform: rotateY(-180deg) rotateZ(90deg);
    }

    66% {
        -moz-transform: rotateY(-270deg) rotateX(90deg);
        -ms-transform: rotateY(-270deg) rotateX(90deg);
        transform: rotateY(-270deg) rotateX(90deg);
    }

    83% {
        -moz-transform: rotateX(90deg);
        -ms-transform: rotateX(90deg);
        transform: rotateX(90deg);
    }
}

.cubespinner-wrap {
    width: var(--DocBrowserLogo-cube-width);
    height: var(--DocBrowserLogo-cube-width);
}

.cubespinner {
    -webkit-animation-name: spincube;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 12s;
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-origin: var(--DocBrowserLogo-cube-width-half) var(--DocBrowserLogo-cube-width-half) 0;
    -moz-transform-origin: var(--DocBrowserLogo-cube-width-half) var(--DocBrowserLogo-cube-width-half) 0;
    -ms-transform-origin: var(--DocBrowserLogo-cube-width-half) var(--DocBrowserLogo-cube-width-half) 0;
    transform-origin: var(--DocBrowserLogo-cube-width-half) var(--DocBrowserLogo-cube-width-half) 0;
}

.cubespinner div {
    position: absolute;
    width: var(--DocBrowserLogo-cube-width);
    height: var(--DocBrowserLogo-cube-width);
    border: 1px solid var(--cerulean);
    background: rgba(30, 30, 30, .7);
    box-shadow: inset 0 0 20px rgba(0, 0, 0, .2);
    line-height: var(--DocBrowserLogo-cube-width);
    text-align: center;
    font-size: 20px;
    color: var(--cerulean);
}

.cubespinner .face1 {
    -webkit-transform: translateZ(var(--DocBrowserLogo-cube-width-half));
    -moz-transform: translateZ(var(--DocBrowserLogo-cube-width-half));
    -ms-transform: translateZ(var(--DocBrowserLogo-cube-width-half));
    transform: translateZ(var(--DocBrowserLogo-cube-width-half));
}

.cubespinner .face2 {
    -webkit-transform: rotateY(90deg) translateZ(var(--DocBrowserLogo-cube-width-half));
    -moz-transform: rotateY(90deg) translateZ(var(--DocBrowserLogo-cube-width-half));
    -ms-transform: rotateY(90deg) translateZ(var(--DocBrowserLogo-cube-width-half));
    transform: rotateY(90deg) translateZ(var(--DocBrowserLogo-cube-width-half));
}

.cubespinner .face3 {
    -webkit-transform: rotateY(90deg) rotateX(90deg) translateZ(var(--DocBrowserLogo-cube-width-half));
    -moz-transform: rotateY(90deg) rotateX(90deg) translateZ(var(--DocBrowserLogo-cube-width-half));
    -ms-transform: rotateY(90deg) rotateX(90deg) translateZ(var(--DocBrowserLogo-cube-width-half));
    transform: rotateY(90deg) rotateX(90deg) translateZ(var(--DocBrowserLogo-cube-width-half));
}

.cubespinner .face4 {
    -webkit-transform: rotateY(180deg) rotateZ(90deg) translateZ(var(--DocBrowserLogo-cube-width-half));
    -moz-transform: rotateY(180deg) rotateZ(90deg) translateZ(var(--DocBrowserLogo-cube-width-half));
    -ms-transform: rotateY(180deg) rotateZ(90deg) translateZ(var(--DocBrowserLogo-cube-width-half));
    transform: rotateY(180deg) rotateZ(90deg) translateZ(var(--DocBrowserLogo-cube-width-half));
}

.cubespinner .face5 {
    -webkit-transform: rotateY(-90deg) rotateZ(90deg) translateZ(var(--DocBrowserLogo-cube-width-half));
    -moz-transform: rotateY(-90deg) rotateZ(90deg) translateZ(var(--DocBrowserLogo-cube-width-half));
    -ms-transform: rotateY(-90deg) rotateZ(90deg) translateZ(var(--DocBrowserLogo-cube-width-half));
    transform: rotateY(-90deg) rotateZ(90deg) translateZ(var(--DocBrowserLogo-cube-width-half));
}

.cubespinner .face6 {
    -webkit-transform: rotateX(-90deg) translateZ(var(--DocBrowserLogo-cube-width-half));
    -moz-transform: rotateX(-90deg) translateZ(var(--DocBrowserLogo-cube-width-half));
    -ms-transform: rotateX(-90deg) translateZ(var(--DocBrowserLogo-cube-width-half));
    transform: rotateX(-90deg) translateZ(var(--DocBrowserLogo-cube-width-half));
}

.FormDialog {
    font-weight: var(--weight-light);
    max-width: 600px;
    text-align: center;
    width: 80%
}

.FormDialog div {
        padding: 0 var(--global-padding);
    }

.FormDialog div:first-of-type {
        height: 130px;
        background-color: var(--cerulean);
        background-image: url(/static/media/icon-check.ba447259.svg);
        background-position: 50%;
        background-repeat: no-repeat;
    }

.FormDialog.error div:first-of-type {
        background-color: var(--brightRed-lightness75);
        background-image: url(/static/media/icon-cross.bab1b4f2.svg);
    }

.FormDialog h1 {
        font-size: 27px;
        font-weight: var(--weight-bold);
        margin: 30px 0 10px;
    }

.FormDialog p {
        margin: 10px 0 44px;
    }

.FormDialog-backLink {
    display: inline-block;
    margin: 0 0 30px;
}

.Form {

}

.FormValidationMessage {
    background-color: var(--brightRed-lightness75);
    background-image: url(/static/media/icon-cross.bab1b4f2.svg);
    background-position: 10px 1.25em;
    background-repeat: no-repeat;
    background-size: 26px;
    color: var(--brightRed);
    font-size: 14px;
    font-weight: var(--weight-light);
    margin-bottom: 20px;
    padding: 20px;
    padding-left: 44px
}
.FormValidationMessage p {
        margin: 0 0 .5em;
    }
.FormValidationMessage ul {
        margin: 0;
    }

.HashAnchor {
    overflow: hidden;
    position: relative
}
.HashAnchor > span {
        left: 0;
        margin-top: calc(var(--global-header-height) * -1);
        position: absolute;
        top: 0;
    }

.Highlight {
    position: relative;
    display: inline-block
}
@media (max-width: 1100px) {
        .Highlight.mobileModal .contentWrap:hover .content {
            display: none;
        }

        .Highlight.mobileModal.mobileContentVisible .content {
            display: var(--Highlight-mobileContentVisible);
            position: fixed;
            inset: 0;
            transform: none;
            display: flex !important;
            align-items: center;
            justify-content: center;
            background-color: rgba(24, 24, 24, 0.7);
            width: 100%;
            z-index: 10;
        }

        .Highlight.mobileModal.mobileContentVisible .contentInner {
            padding: 30px 30px 20px;
            max-width: 300px;
            position: relative;
        }

        .Highlight.mobileModal.mobileContentVisible .contentInner:before {
            display: none;
        }


        .Highlight.mobileModal.mobileContentVisible .close {
            position: absolute;
            top: -25px;
            right: 0px;
            color: #fff;
            cursor: pointer;
            width: 20px;
            height: 20px;
            transform: rotate(45deg);
            display: block;
        }

        .Highlight.mobileModal.mobileContentVisible .close::before,
        .Highlight.mobileModal.mobileContentVisible .close::after {
            width: 20px;
            height: 3px;
            background: #fff;
            content: "";
            transition: transform 0.1s;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }

        .Highlight.mobileModal.mobileContentVisible .close::after {
            transform: rotate(90deg);
        }
    }
.Highlight .close {
        display: none;
    }
.Highlight .iconWrap {
        display: inline-block;
        position: relative;
        z-index: 3;
    }
.Highlight .iconWrap img {
        display: block;
    }
.Highlight .iconWrap:hover {
        cursor: pointer;
    }
.Highlight .contentWrap:hover .content {
        display: block;
    }
.Highlight .content {
        position: absolute;
        top: 13px;
        left: calc(100% + 12px);
        color: #fff;
        display: none;
        line-height: 1.5;
        transform: translateY(-50%);
        z-index: 2;
    }
.Highlight .content a {
        color: #fff;
    }
.Highlight .contentInner {
        background: var(--cerulean);
        padding: 4px 10px;
    }
.Highlight .contentInner:before {
        content: "";
        position: absolute;
        top: calc(50% - 7px);
        left: -20px;
        border: 7px solid transparent;
        border-left-width: 10px;
        border-right-width: 10px;
        border-right-color: #0090D3;
    }

:root {
    --Input-borderWidth: 3px;
    --Input-borderTransition: border-color 0.15s ease-in-out;
}

.Input-validationBar {
    border-color: transparent;
    border-style: solid;
    border-width: 0 0 0 var(--Input-borderWidth);
    content: '';
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: var(--Input-borderTransition);
    width: 100%;
}

.Input {
    border: 1px solid var(--very-light-pink);
    display: block;
    font-size: 14px;
    line-height: 1;
    position: relative;
    overflow: hidden
}

.Input.focused span {
        z-index: 3;
    }

.Input.focused span.animated:nth-of-type(2) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: linear-gradient(to right, rgba(255, 255, 255, 0), var(--cerulean));
        animation: animate1 1s linear 1;
        transform: translateX(-100%);
    }

.Input.focused span.animated:nth-of-type(3) {
        position: absolute;
        top: 0;
        right: 0;
        width: 2px;
        height: 100%;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), var(--cerulean));
        animation: animate2 1s linear 1;
        transform: translateY(-100%);
        animation-delay: .5s;
    }

.Input.focused span.animated:nth-of-type(4) {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: linear-gradient(to left, rgba(255, 255, 255, 0), var(--cerulean));
        animation: animate3 1s linear 1;
        transform: translateX(100%);
    }

.Input.focused span.animated:nth-of-type(5) {
        position: absolute;
        top: 0;
        left: 0;
        width: 2px;
        height: 100%;
        background: linear-gradient(to top, rgba(255, 255, 255, 0), var(--cerulean));
        animation: animate4 1s linear 1;
        animation-delay: .5s;
        transform: translateY(100%);
    }

.Input span.label {
        color: var(--brownish-grey-two);
        left: 1em;
        pointer-events: none;
        position: absolute;
        top: 1.6em;
        transform-origin: 0 0;
        transition: transform 0.15s ease-out;
    }

.Input input,
    .Input textarea {
        border-color: transparent;
        border-radius: 0;
        border-style: solid;
        border-width: 0 0 0 var(--Input-borderWidth);
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        padding: 1.8em 1em .6em calc(1em - var(--Input-borderWidth));
        width: 100%;
        outline-color: transparent;
        outline-style: none;
        outline-offset: unset;
    }

.Input input {
        transition: var(--Input-borderTransition)
    }

.Input input:invalid:not([value='']) {
            border-left-color: var(--brightRed);
        }

.Input input:valid:not([value='']) {
            border-left-color: var(--cerulean);
        }

.Input input:focus + span.label,
        .Input input:not([value='']) + span.label {
            transform: scale(0.75) translateY(-1em);
        }

.Input textarea {
        border-top-width: 1.8em;
        padding-top: 0;
        resize: vertical
    }

.Input textarea:invalid:not(:empty) ~ .Input-validationBar {
            border-left-color: var(--brightRed);
        }

.Input textarea:valid:not(:empty) ~ .Input-validationBar {
            border-left-color: var(--cerulean);
        }

.Input textarea:focus + span.label,
        .Input textarea:not(:empty) + span.label {
            transform: scale(0.75) translateY(-1em);
        }

.error{
    border: 1px solid var(--brightRed)
}

.error input {
        border-left-color: var(--brightRed) !important;
   }

.inputErrorMessage {
    display: block;
    font-size: 14px;
    padding: 5px 0;
    color: var(--brightRed);
  }

@keyframes animate1 {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}

@keyframes animate2 {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(100%);
    }
}

@keyframes animate3 {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

@keyframes animate4 {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(-100%);
    }
}

.NavButton {
    background-color: transparent;
    border: 0;
    color: var(--cerulean);
    cursor: pointer;
    font-size: .5em;
    height: 5em;
    padding: 0;
    position: relative;
    width: 5em
}
.NavButton::before,
    .NavButton::after {
        background-color: currentColor;
        content: '';
        height: .4em;
        position: absolute;
        right: 1em;
        top: 2.25em;
        transition:
            transform 0.225s ease-in-out, width 0.225s ease-in-out;
    }
.NavButton::before {
        transform: translateY(-0.4em);
        width: 1.5em;
    }
.NavButton::after {
        transform: translateY(.4em);
        width: 3em;
    }
.NavButton.open::before,
        .NavButton.open::after {
            width: 3.5em;
        }
.NavButton.open::before {
            transform: translateX(.3em) rotate(135deg);
        }
.NavButton.open::after {
            transform: translateX(0.3em) rotate(-135deg);
        }

.NavItem {
    color: inherit;
    text-decoration: none;
}

.Option {

}

.PaddedBlock {

}

.PaddedSection {
    padding: var(--global-padding-top-bottom) var(--global-padding);
}

.PaginationButton {
	background: transparent;
	border: 0;
	color: inherit;
	cursor: pointer;
	height: 100%;
	min-height: 150px;
	overflow: hidden;
	padding: 0;
	position: relative;
	width: var(--global-padding)
}
.PaginationButton::before {
		position: absolute;
		border-color: currentColor;
		border-style: solid;
		border-width: 0 0 3px 3px;
		content: '';
		display: block;
		height: 24px;
		opacity: .75;
		top: 55%;
		width: 24px;
	}
.PaginationButton.left::before {
		transform: rotate(45deg);
		left: calc(50% - 5px);
	}
.PaginationButton.right::before {
		right: calc(50% - 5px);
		transform: rotate(-135deg);
	}

:root {
    /* NOTE: Font-size controls overall sizing/scaling */
    --PlayButton-fontSize: 22px;
    --PlayButton-border-offset: .325em;
}

@media (max-width: 720px) {
    :root {
        --PlayButton-fontSize: 10px;
        --PlayButton-border-offset: .75em;
    }
}

.PlayButton {
    align-items: center;
    background-color: transparent;
    border: 0;
    color: var(--cerulean);
    cursor: pointer;
    display: flex;
    font-size: var(--PlayButton-fontSize);
    height: 6.9em;
    justify-content: center;
    padding: 0;
    position: relative;
    width: 6.9em;
    z-index: 0
}

.PlayButton::before,
    .PlayButton::after {
        background-color: currentColor;
        border-radius: 100%;
        content: '';
        position: absolute;
    }

.PlayButton::before {
        height: 100%;
        left: 0;
        opacity: .25;
        top: 0;
        width: 100%;
        transition: transform 0.25s ease-in-out;
    }

.PlayButton::after {
        height: calc(100% - var(--PlayButton-border-offset) * 2);
        left: var(--PlayButton-border-offset);
        top: var(--PlayButton-border-offset);
        width: calc(100% - var(--PlayButton-border-offset) * 2);
        transition: transform 0.15s ease-in-out;
    }

.PlayButton:hover::before {
            transform: scale(1.1);
        }

.PlayButton:hover::after {
            transform: scale(1.11);
        }

.PlayButton-arrow {
    border-color: transparent transparent transparent white;
    border-style: solid;
    border-width: 1.25em 0 1.25em 1.5em;
    height: 0;
    margin: auto;
    transform: translateX(.25em);
    width: 0;
    z-index: 1;
}

.Row {
    display: flex;
    margin-left: -20px;
    margin-right: -20px;
}


	.SlidePagination label {
		cursor: pointer;
		position: relative;
		overflow: hidden
	}
.SlidePagination label:not(:last-of-type) {
			margin-right: 8px;
		}
.SlidePagination input {
		height: 0;
		left: 0;
		margin: 0;
		opacity: 0;
		position: absolute;
		top: 0;
		width: 0;
	}
.SlidePagination span {
		border: 1px solid currentColor;
		display: inline-block;
		height: 10px;
		position: relative;
		width: 10px;
	}
.SlidePagination input:checked + span {
		background: currentColor;
	}
.SlidePagination input:focus + span::after {
		border: 1px solid currentColor;
		content: '';
		height: calc(100% + 6px);
		left: -3px;
		position: absolute;
		top: -3px;
		width: calc(100% + 6px);
	}

.YouTubePlayer {
    width: 100%;
    height: 100%;
}

:root {
    --TextOnly-h1-margin: 0 0 20px;
    --TextOnly-h2-fontSize: 20px;
    --TextOnly-h2-margin: 0 0 10px;
    --TextOnly-p-fontSize: 20px;
}

@media (max-width: 720px) {
    :root {
        --TextOnly-h1-margin: 0 0 15px;
        --TextOnly-h2-fontSize: 14px;
        --TextOnly-h2-margin: 0 0 10px;
        --TextOnly-p-fontSize: 16px;
    }
}

.TextOnly h1 {
        color: var(--black-three);
        font-size: var(--h1-font-size);
        font-weight: var(--weight-bold);
        line-height: 1.4;
        margin: var(--TextOnly-h1-margin);
    }

.TextOnly h2 {
        color: var(--cerulean);
        font-size: var(--TextOnly-h2-fontSize);
        font-weight: var(--weight-bold);
        line-height: 1;
        margin: var(--TextOnly-h2-margin);
        text-transform: uppercase;
    }

.TextOnly p {
        color: var(--brownish-grey-two);
        font-size: var(--TextOnly-p-fontSize);
        font-weight: var(--weight-light);
        margin: 0;
        max-width: 820px;
    }

:root {
  --AppointmentBooking-textOnly-formSection-margin-top: -24px;
  --AppointmentBooking-bookingContainer-grid-template-columns: 60% 35%;
  --AppointmentBooking-textOnly-text-align: left;
  --AppointmentBooking-calendarSection-justify-content: left;
  --AppointmentBooking-bookingContainer-template-areas: 'header calendars' 'form calendars' 'form calendars'
    'form calendars';

  --AppointmentBooking-bookingContainer-asModal-grid-template-columns: calc(100% - 476px - 5%) 476px;
  --AppointmentBooking-bookingContainer-asModal-calendar-background: var(--cerulean);
  --AppointmentBooking-calendarSection-margin-left: auto;
  --AppointmentBooking-timePickerLabel-color: white;
  --AppointmentBooking-bookingContainer-asModal-gap: 5%;
  --AppointmentBooking-calendarSection-padding: 60px;
  --AppointmentBooking-formSection-padding: 0 60px 60px 60px;
  --AppointmentBooking-headerSection-padding: 60px 60px 0 60px;
}

@media (max-width: 1024px) {
  :root {
    --AppointmentBooking-textOnly-formSection-margin-top: 24px;
    --AppointmentBooking-bookingContainer-grid-template-columns: 100%;
    --AppointmentBooking-textOnly-text-align: center;
    --AppointmentBooking-calendarSection-justify-content: center;
    --AppointmentBooking-bookingContainer-template-areas: 'header' 'calendars' 'form';

    --AppointmentBooking-bookingContainer-asModal-grid-template-columns: inherit;
    --AppointmentBooking-bookingContainer-asModal-calendar-background: white;
    --AppointmentBooking-calendarSection-margin-left: inherit;
    --AppointmentBooking-timePickerLabel-color: inherit;
    --AppointmentBooking-bookingContainer-asModal-gap: unset;
    --AppointmentBooking-calendarSection-padding: 30px;
    --AppointmentBooking-formSection-padding: 0 30px 30px 30px;
    --AppointmentBooking-headerSection-padding: 30px 30px 0 30px;
  }
    .AppointmentBookingAsModal .headerSection {
      padding: unset;
      max-width: 75%;
      margin: auto;
    }

    .AppointmentBookingAsModal .bookingContainer {
      gap: unset;
    }

    .AppointmentBookingAsModal .formSection {
      padding: unset;
      max-width: 75%;
      margin: auto;
    }

    .AppointmentBookingAsModal .calendarSection {
      padding: unset
    }
      .AppointmentBookingAsModal .calendarSection .react-calendar,
      .AppointmentBookingAsModal .calendarSection .timePickerContainer {
        max-width: 70%;
        margin: auto;
      }

      .AppointmentBookingAsModal .calendarSection .timePickerContainer {
        margin-top: 30px;
      }
}

.AppointmentBooking .textOnly {
    padding: 0;
    margin: 0;
    text-align: var(--AppointmentBooking-textOnly-text-align);
    padding-bottom: 20px
  }

.AppointmentBooking .textOnly p {
      margin: 0;
    }

.AppointmentBooking form {
    max-width: 100%;
  }

.AppointmentBooking form > label {
    max-width: 540px;
  }

.AppointmentBooking .bookingContainer {
    display: grid;
    gap: 20px;
    justify-content: center;
    grid-auto-rows: minmax(100px, auto);
    grid-template-columns: var(--AppointmentBooking-bookingContainer-grid-template-columns);
    grid-template-areas: var(--AppointmentBooking-bookingContainer-template-areas);
  }

.AppointmentBooking .calendarSection {
    grid-area: calendars;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: var(--AppointmentBooking-calendarSection-justify-content);
  }

.AppointmentBooking .headerSection {
    grid-area: header;
  }

.AppointmentBooking .formSection {
    grid-area: form;
    margin-top: var(--AppointmentBooking-textOnly-formSection-margin-top);
    display: flex;
    justify-content: var(--AppointmentBooking-calendarSection-justify-content);
  }

.AppointmentBooking .invisibleButton{
    visibility: hidden;
  }

.AppointmentBooking ul {
    padding: 0;
    margin: 0;
  }

.AppointmentBooking .optionListItem {
    list-style-type: none
  }

.AppointmentBooking .optionListItem label {
      display: inline-flex;
      align-items: center;
    }

.AppointmentBooking .optionListItem .labelText {
      padding: 0.8rem 0 0.8rem 1rem;
      margin: 0;
    }

.AppointmentBooking .bookOptionsHeading,
  .AppointmentBooking .labelText {
    color: var(--brownish-grey-two);
    font-size: var(--TextOnly-p-fontSize);
    font-weight: var(--weight-light);
    margin: 20px 0;
  }

.AppointmentBooking .Booking-Button {
    width: 350px;
    max-width: 100%;
    margin-top: 20px;
    text-align: center;
  }

.AppointmentBooking .calendar {
    padding: 20px;
    box-shadow: 0px 6px 12px rgba(19, 90, 149, 0.2);
    border: none
  }

.AppointmentBooking .calendar .react-calendar__navigation {
      height: 32px;
      margin-bottom: 0.8rem;
    }

.AppointmentBooking .calendar .react-calendar__navigation__prev-button,
    .AppointmentBooking .calendar .react-calendar__navigation__next-button {
      background: transparent !important;
      font-size: 18px;
      user-select: none
    }

.AppointmentBooking .calendar .react-calendar__navigation__prev-button:hover, .AppointmentBooking .calendar .react-calendar__navigation__next-button:hover {
        background: #0090d31a !important;
      }

.AppointmentBooking .calendar .react-calendar__navigation__label {
      font-size: 16px;
      font-weight: bold;
      background: transparent !important;
    }

.AppointmentBooking .calendar .react-calendar__navigation__next2-button {
      display: none;
    }

.AppointmentBooking .calendar .react-calendar__navigation__prev2-button {
      display: none;
    }

.AppointmentBooking .calendar .react-calendar__tile:hover {
      background: var(--very-light-blue);
    }

.AppointmentBooking .calendar .react-calendar__tile--now {
      background: inherit;
      color: black !important;
    }

.AppointmentBooking .calendar .react-calendar__tile--active {
      background: var(--cerulean) !important;
      color: white !important;
    }

.AppointmentBooking .calendar .react-calendar__tile:disabled{
      color: inherit !important;
      background: #f0f0f0;
    }

.AppointmentBooking .calendar .react-calendar__month-view__weekdays__weekday {
      color: var(--brownish-grey-two)
    }

.AppointmentBooking .calendar .react-calendar__month-view__weekdays__weekday abbr {
        border-bottom: none !important;
        cursor: inherit !important;
        text-decoration: none !important;
      }

.AppointmentBooking .todayClassName{
    background: var(--brown-grey-50) !important;
  }

.AppointmentBooking .timePickerContainer {
    margin-top: 40px;
    max-width: 100%
  }

.AppointmentBooking .timePickerContainer > span {
      display: block;
      margin-bottom: 5px;
    }

.AppointmentBooking .timePickerContainer > span:last-of-type {
      margin-top: 5px;
    }

.AppointmentBookingAsModal {
  padding: 0;
  justify-content: space-between
}

.AppointmentBookingAsModal .bookingContainer {
    grid-template-columns: var(--AppointmentBooking-bookingContainer-asModal-grid-template-columns);
    gap: var(--AppointmentBooking-bookingContainer-asModal-gap)
  }

.AppointmentBookingAsModal .headerSection {
    padding: var(--AppointmentBooking-headerSection-padding);
  }

.AppointmentBookingAsModal .formSection {
    padding: var(--AppointmentBooking-formSection-padding);
  }

.AppointmentBookingAsModal .timePickerLabel {
    color: var(--AppointmentBooking-timePickerLabel-color);
  }

.AppointmentBookingAsModal .calendarSection {
    max-height: 100%;
    padding: var(--AppointmentBooking-calendarSection-padding);
    margin-left: var(--AppointmentBooking-calendarSection-margin-left);
    background: var(--AppointmentBooking-bookingContainer-asModal-calendar-background);
  }

.TimePicker {
  width: 350px;
  max-width: 100%;
  height: 257px;
  padding: 20px;
  box-shadow: 0px 6px 12px rgba(19, 90, 149, 0.2);
  background-color: white;
  overflow: hidden;
  position: relative
}

.TimePicker ::-webkit-scrollbar {
    width: 5px !important;
  }

.TimePicker ::-webkit-scrollbar-track {
    background-color: transparent !important;
    margin-left: 8px !important;
  }

.TimePicker ::-webkit-scrollbar-thumb {
    background: var(--cerulean) !important;
    border-radius: 100px !important;
  }

.TimePicker .slotsContainer {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    overflow-y: scroll;
    margin-right: -15px;
    padding-right: 10px;
  }

.TimePicker .slot {
    display: inline-block;
    padding: 10px 4px;
    font-size: 14px;
    background: var(--very-light-blue);
    cursor: pointer;
    text-align: center;
    user-select: none;
  }

.TimePicker .active {
    background: var(--cerulean);
    color: white;
  }

.errorBorder {
  border: 1px solid var(--brightRed)
}

.timePickerError {
  display: block;
  font-size: 14px;
  padding: 5px 0;
  color: var(--brightRed);
}

.videoBox {
    padding-bottom: 51.25%;
    overflow: hidden;
    position: relative;
    width: 100%;
    /* height: 100%; */
    cursor: pointer;
    display: block;
 }
 
 .youtube-video {
    position: absolute;
    top: 0;
    left: 0;
 }
 
 .videoButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
 }
 
 .videoBoxImg {
    bottom: 0;
    display: block;
    left: 0;
    margin: auto;
    max-width: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    height: auto;
 }


:root {
    --very-light-pink: #ededed;
    --accordion-inner-padding: 38px 40px;
    --accordion-item-border: 1px solid var(--very-light-pink);
    --accordion-border-top: 1px solid var(--very-light-pink);
    --answer-wrap-width: 95%;
}

@media (--mq-width-720) {
	:root {
		--answer-wrap-width: 100%;
		--accordion-inner-padding: 24px 0px;
		--accordion-answer-paddingTop: 5px;
		--accordion-border-top: none;
	}

	.AccordionItem {
		border-right: none !important;
		border-left: none !important;
	}

	.AccordionItemAnswerOpen {
		padding: 0;
	}
}

.AccordionItem {
	margin-bottom: 24px;
	border: var(--accordion-item-border);
	position: relative;
}

.AccordionItem:last-child {
	margin-bottom: 0;
}

.AccordionItemOpen {
	border-top: var(--accordion-border-top);
}

.AccordionItemWrap {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: var(--accordion-inner-padding);
	line-height: 1.6rem;
	cursor: pointer;
}

.AccordionItemAnswer {
	overflow: hidden;
	max-width: var(--answer-wrap-width);
	margin: 0;
	height: 0;
	color: var(--brownish-grey-two);
	transition: 0.3s;
	padding: var(--accordion-inner-padding);
	padding-top: 0;
	padding-bottom: 0;
}

.AccordionItemAnswerOpen {
	max-height: auto;
	margin-bottom: 1em;
	padding-top: 0px;
}

.videoBoxWrap {
	margin-top: 20px;
}

.listStyle p {
	margin-top: 0;
	padding: 0;
}

.listStyle ul {
	margin: 0;
	padding-left: 10px;
}

.listStyle ul li {
	margin: 1.4rem;
	padding-left: 0.6rem;
}


:root {
  --BrandIcons-grid-template-columns: repeat(2, 1fr);
  --BrandIcons-icon-div-justify: flex-start;
}

@media (max-width: 1024px) {
  :root {
    --BrandIcons-icon-div-justify: center;
  }
}

@media (max-width: 720px) {
  :root {
    --BrandIcons-grid-template-columns: 1fr;
  }
}

.BrandIcons {
  display: grid;
  grid-template-columns: var(--BrandIcons-grid-template-columns)
}

.BrandIcons > div {
    display: inline-flex;
    align-items: center;
    justify-content: var(--BrandIcons-icon-div-justify);
    padding: 10px;
  }

@media (max-width: 720px) {
    .ContactForm {
        --ContactForm-split-display: block;
    }
}

.ContactForm form a,
    .ContactForm form p {
        font-size: 14px;
        font-weight: var(--weight-light);
    }

.ContactForm form a {
        color: var(--cerulean);
        text-decoration: underline;
    }

.ContactForm form p {
        margin: 0 0 32px;
        color: var(--brownish-grey-two);
    }

.ContactForm form label {
        margin-bottom: 1.5em;
    }

.ContactForm-split {
    display: var(--ContactForm-split-display, grid);
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1.3em;
}

.ContactForm-button {
    max-width: 350px;
    font-weight: var(--weight-bold);
}

:root {
    --TextOnly-h2-fontSize: 18px;
}
  
@media (--mq-width-720) {
    :root {
    --TextOnly-h2-fontSize: 14px;
    }
}
  
.FaqHeading {
    color: var(--dark-grey);
    font-size: var(--h1-font-size);
    font-weight: var(--weight-medium);
    margin: 0;
}
  
.FaqSubHeading {
    color: var(--cerulean);
    font-size: var(--TextOnly-h2-fontSize);
    font-weight: var(--weight-medium);
    text-transform: uppercase;
    margin: 0;
}
  
.FaqHeading {
    margin-bottom: 30px;
}

:root {
	--Modal-modal-body: 85vh;
}

@media (max-width: 720px) {
	:root {
		--Modal-modal-body: 60vh;
	}
}

@media (orientation: landscape) and (max-width: 767px) {
	:root {
		--Modal-modal-body: 75vh;
	}
}

.Modal .modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: 10000;
}

.Modal .modal.visible {
  opacity: 1;
}

.Modal .modal-body {
  position: relative;
  height: var(--Modal-modal-body);
  width: 100vw;
}

.Modal .modal-body iframe {
  position: relative;
}

.Modal .modal-body .close {
  position: absolute;
  top: -25px;
  right: 20px;
  color: #fff;
  cursor: pointer;
  width: 20px;
  transform: rotate(45deg);
}

.Modal .modal-body .close::before,
.Modal .modal-body .close::after {
  width: 20px;
  height: 3px;
  background: #fff;
  content: "";
  transition: transform 0.1s;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.Modal .modal-body .close::after {
  transform: rotate(90deg);
}

:root {
  --NavItem-display: flex;
  --NavItemGroup-a-fontSize: 14px;
  --NavItemGroup-subItemLabel-right: -20px;
  --NavItemGroup-subItemLabel-left: inherit;
  --NavItemGroup-subItemLabel-top: -27px;
  --NavItemGroup-subItemLabel-fontSize: 12px;
  --NavItemGroup-a-fontWeight: var(--weight-bold);
  --NavItemGroup-a-marginBottom: 0;
  --NavItemGroup-a-padding: 0;
  --NavItemGroup-a-textTransform: uppercase;
  --NavItemGroup-ul-flexDirection: row;
  --NavItemGroup-subItemContainer-padding-top: 25px;
  --NavItemGroup-subItemContainer-position: absolute;
  --NavItemGroup-subItemContainer-padding-left: 0;
  --NavItemGroup-subItemContainer-visibility: hidden;
  --NavItemGroup-subItemContainer-a-fontSize: 12px;
  --NavItemGroup-subItemContainer-a-lineHeight: 1rem;
  --NavItemGroup-subItemContainer-a-backGroundColor: rgba(31, 31, 31, 0.9);
  --NavItemGroup-subItemContainer-a-padding: 8px 20px;
  --NavItemGroup-subItemLi-flex-direction: row;
  --NavItemGroup-subItemLi-margin: 0 0 var(--NavItemGroup-a-marginBottom);
  --NavItemGroup-subItemLiIcon-display: inline-block;
  --NavItemGroup-subItemContainer-opacity: 0;
  --NavItemGroup-subItemContainer-li-a-padding-top-bottom: 20px;
  --NavItemGroup-subItemContainer-li-margin: 0 0 var(--NavItemGroup-a-marginBottom);
}

@media (max-width: 1199px) {
  :root {
    --NavItemGroup-a-fontSize: 11px;
    --NavItemGroup-subItemLabel-fontSize: 11px;
  }
}

@media (max-width: 1064px) {
  :root {
    --NavItem-display: block;
    --NavItemGroup-a-fontSize: 18px;
    --NavItemGroup-a-fontWeight: var(--weight-bold);
    --NavItemGroup-a-marginBottom: 32px;
    --NavItemGroup-a-textTransform: none;
    --NavItemGroup-subItemLabel-right: inherit;
    --NavItemGroup-subItemLabel-left: 40px;
    --NavItemGroup-subItemLabel-top: -22px;
    --NavItemGroup-ul-flexDirection: column;
    --NavItemGroup-a-padding: 2px 10px;
    --NavItemGroup-subItemContainer-position: static;
    --NavItemGroup-subItemContainer-padding-left: 2rem;
    --NavItemGroup-subItemContainer-a-fontSize: 16px;
    --NavItemGroup-subItemContainer-a-lineHeight: 1.3;
    --NavItemGroup-subItemContainer-a-backGroundColor: inherit;
    --NavItemGroup-subItemContainer-a-padding: 6px 10px;
    --NavItemGroup-subItemListItem: 6px 10px;
    --NavItemGroup-subItemLi-flex-direction: column;
    --NavItemGroup-subItemLi-margin: 0;
    --NavItemGroup-subItemContainer-padding-top: 0;
    --NavItemGroup-subItemContainer-visibility: visible;
    --NavItemGroup-subItemLiIcon-display: none;
    --NavItemGroup-subItemContainer-opacity: 1;
    --NavItemGroup-subItemContainer-li-a-padding-top-bottom: 6px;
    --NavItemGroup-subItemContainer-li-margin: 10px 0;
  }
}

.NavItemGroup ul {
    display: var(--NavItem-display);
    flex-direction: var(--NavItemGroup-ul-flexDirection);
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

.NavItemGroup li {
    display: inherit;
    position: relative;
    cursor: pointer
  }

.NavItemGroup li a {
      position: relative
    }

.NavItemGroup li a .subItemLabel {
        font-style: normal;
        top: var(--NavItemGroup-subItemLabel-top);
        right: var(--NavItemGroup-subItemLabel-right);
        left: var(--NavItemGroup-subItemLabel-left);
        font-weight: 700;
        font-size: var(--NavItemGroup-subItemLabel-fontSize);
        padding: 5px 7px 4px;
        background: #0090D3;
        border-radius: 100px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        color: #fff;
      }

.NavItemGroup li:not(:last-child) {
      margin: 0 0 var(--NavItemGroup-a-marginBottom);
    }

.NavItemGroup li .subItemContainer {
      all: unset;
      position: var(--NavItemGroup-subItemContainer-position);
      top: 0;
      right: 0;
      padding-top: var(--NavItemGroup-subItemContainer-padding-top);
      padding-left: var(--NavItemGroup-subItemContainer-padding-left);
      white-space: nowrap;
      visibility: var(--NavItemGroup-subItemContainer-visibility);
      opacity: var(--NavItemGroup-subItemContainer-opacity);
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out
    }

.NavItemGroup li .subItemContainer > li.subItemsListItem {
        margin: var(--NavItemGroup-subItemContainer-li-margin);
      }

.NavItemGroup li .subItemContainer a {
        font-size: var(--NavItemGroup-subItemContainer-a-fontSize);
        line-height: var(--NavItemGroup-subItemContainer-a-lineHeight);
        background: var(--NavItemGroup-subItemContainer-a-backGroundColor);
        padding: var(--NavItemGroup-subItemContainer-a-padding);
      }

.NavItemGroup li .subItemContainer li:first-child > a {
        padding-top: var(--NavItemGroup-subItemContainer-li-a-padding-top-bottom);
      }

.NavItemGroup li .subItemContainer li:last-child > a {
        padding-bottom: var(--NavItemGroup-subItemContainer-li-a-padding-top-bottom);
      }

.NavItemGroup li .subItemLiIcon {
      display: var(--NavItemGroup-subItemLiIcon-display);
    }

.NavItemGroup li:hover > a {
        color: var(--bright-cyan);
      }

.NavItemGroup li:hover .subItemContainer {
        visibility: visible;
        opacity: 1;
      }

.NavItemGroup li:hover .subItemLiIcon::before {
        transition: background-image 0.2s ease;
        background-image: url(/static/media/navigation-arrow-up.6c84ba13.svg);
      }

.NavItemGroup li.subItemLi {
    display: flex !important;
    flex-direction: var(--NavItemGroup-subItemLi-flex-direction);
    margin: var(--NavItemGroup-subItemLi-margin)
  }

.NavItemGroup li.subItemLi > span {
      position: relative;
      z-index: 1;
      font-size: var(--NavItemGroup-a-fontSize);
      font-weight: var(--NavItemGroup-a-fontWeight);
      text-transform: var(--NavItemGroup-a-textTransform);
      transition: 0.4s;
      padding: var(--NavItemGroup-a-padding)
    }

.NavItemGroup li.subItemLi > span:hover {
        color: var(--bright-cyan);
      }

.NavItemGroup a {
    font-size: var(--NavItemGroup-a-fontSize);
    font-weight: var(--NavItemGroup-a-fontWeight);
    text-transform: var(--NavItemGroup-a-textTransform);
    transition: 0.4s;
    padding: var(--NavItemGroup-a-padding);
    display: block
  }

.NavItemGroup a:hover {
      color: var(--bright-cyan);
    }

.subItemLiIcon {
  height: 0
}

.subItemLiIcon::before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-bottom: 0.3rem;
    vertical-align: middle;
    margin-left: 0.4rem;
    background-image: url(/static/media/navigation-arrow-down.b9d0f462.svg);
    background-position: center center;
    background-repeat: no-repeat;
    transition: background-image 0.2s ease;
  }

.displayAsButton{
  display: inline-flex;
  max-width: max-content;
  padding: 5px 10px;
  background: var(--cerulean);
  border-radius: 2px;
  user-select: none;
  align-items: top;
  color: white;
}

:root {
  --NewInfoBlock-width: 49%;
}

@media (max-width: 720px) {
  :root {
    --NewInfoBlock-width: 100%;
  }
}

.NewInfoBlock {
  height: 505px;
  box-sizing: border-box;
  /* width: var(--NewInfoBlock-width); */
  background: #ffffff;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.1);
  margin-bottom: 25px;
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  padding-bottom: 50px;
}

.ImageContainer {
  height: 235px;
  width: 100%;
  object-fit: cover;
  object-position: top;
}

.ScreenContainer {
  height: 235px;
  width: 100%;
  cursor: pointer;
  object-fit: cover;
}

.VideoContainer {
  position: relative;
}

.VideoContainerDialog {
  max-width: 100%;
  margin-top: 150px;
  width: 100%;
}

.VideoButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2
}

.VideoButton button {
    font-size: 10px;
  }

.FormDialog {
  max-width: 600px;
  text-align: center;
  width: 80%;
}

.DescriptionContainer {
  padding: 0 20px;
}

.CategoryBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--brownish-grey-two);
  font-size: 16px;
  font-weight: var(--weight-semiBold);
}

.DescriptionTitle {
  font-size: 20px;
  font-weight: var(--weight-semiBold);
}

.CategoryValue {
  margin-bottom: 5px;
}

.Description {
  color: var(--brownish-grey-two);
  font-size: 16px;
  font-weight: var(--weight-regular);
}

.ReadMore {
  color: var(--cerulean);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px 20px;
  background-color: #ffffff;
  box-shadow: 0px -11px 20px 10px #fff;
  cursor: pointer;
  display: flex;
  align-items: center
}

.ReadMore:after {
    content: '';
    display: inline-block;
    background-image: url(/static/media/read-more-arrow.aac53c99.svg);
    background-size: contain;
    width: 13px;
    height: 8px;
    margin-left: 10px;
    transform: rotate(180deg);
    transition: 0.3s;
  }

.ReadMoreText {
  margin-top: 15px;
  margin-bottom: 0;
  cursor: pointer;
}

.NewInfoBlockExpanded {
  height: auto;
}

.NewInfoBlockExpanded .ReadMore {
  box-shadow: none
}

.NewInfoBlockExpanded .ReadMore:after {
    transform: rotate(0);
  }

.pagination-container {
  display: flex;
  list-style-type: none;
}
.pagination-item {
  padding: 0 7px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px
}
.pagination-item.dots:hover {
    background-color: transparent;
    cursor: default;
  }
.pagination-item:hover {
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
  }
.pagination-item.selected {
    background-color: rgba(0, 0, 0, 0.08);
  }
.arrow::before {
    position: relative;
    /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
    content: "";
    /* By using an em scale, the arrows will size with the font */
    display: inline-block;
    width: 0.4em;
    height: 0.4em;
    border-right: 0.12em solid rgba(0, 0, 0, 0.87);
    border-top: 0.12em solid rgba(0, 0, 0, 0.87);
  }
.arrow.left {
    transform: rotate(-135deg) translate(-50%);
  }
.arrow.right {
    transform: rotate(45deg);
  }
.arrow.disabled {
    pointer-events: none;

    .arrow::before {
      border-right: 0.12em solid rgba(0, 0, 0, 0.43);
      border-top: 0.12em solid rgba(0, 0, 0, 0.43);
    }
  }
.arrow.disabled:hover {
      background-color: transparent;
      cursor: default;
    }

:root {
  --Select-width: 340px;
  --Select-margin-top: 0;
}

@media (max-width: 720px) {
  :root {
    --Select-width: 100%;
    --Select-margin-top: 20px;
  }
}

.Select {
  display: inline-block;
  cursor: pointer;
  position: relative;
  top: 0;
  margin: 0;
  height: 56px;
  width: var(--Select-width);
  margin-top: var(--Select-margin-top);
  color: var(--brownish-grey-two);
  font-size: 14px;
  font-weight: var(--weight-medium);
  border: 1.3329px solid #EDEDED;
  padding: 18px
}

.Select > span{
    user-select: none;
  }

.OptionsWrapper {
  display: none;
  list-style: none;
  padding: 5px 0;
  margin: 0;
  position: absolute;
  border: 1px solid #ececec;
  left: -1px;
  top: 54px;
  right: -2px;
  z-index: 4;
  background: white;
  margin: 0;
  box-sizing: border-box;
}

.OptionsWrapperWrapOpen {
  display:inline-block;
}

.option {
  display: block;
  padding: 5px 10px;
  text-decoration: none;
  color: var(--brownish-grey-two);
  font-size: 14px;
  font-weight: var(--weight-medium);
  user-select: none
}

.option:hover,
  .option.selected {
    background-color: var(--cerulean);
    color: #fff;
  }

.TermsDialog {
    color: var(--almostBlack);
    font-size: 14px;
    padding: 3em 2.5em
}

.TermsDialog h1 {
        font-size: 2.5em;
        margin: 0;
    }

.TermsDialog h2 {
        color: var(--brownish-grey-two);
        font-size: 1.15em;
        margin: 2.25em 0 1em;
        text-transform: uppercase;
    }

.TermsDialog p:first-of-type {
        color: var(--brownish-grey-two);
        margin-top: .75em;
    }

.TermsDialog strong {
        color: var(--cerulean);
    }

.TermsDialog ol,
    .TermsDialog ul {
        padding-left: 24px;
    }

.TermsDialog li {
        margin-bottom: .5em;
        padding-left: .5em;
    }

.TermsDialog-button {
    background-color: transparent;
    background-image: url(/static/media/icon-cross-grey.9d9b16b7.svg);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 40px;
    border: 0;
    cursor: pointer;
    height: 28px;
    position: absolute;
    right: 1.25em;
    top: 1.25em;
    width: 28px;
}


:root {
  --TextSlider-button-right-position: 0;
  --TextSlider-button-left-position: 0;
}

@media (max-width: 720px) {
  :root {
    --TextSlider-button-right-position: -40px;
    --TextSlider-button-left-position: -40px;
  }
}

@media (max-width: 360px) {
  :root {
    --TextSlider-button-right-position: -20px;
    --TextSlider-button-left-position: -20px;
  }
}

.slider {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  margin-top: 50px;
}

.TextSlider-button-right,
.TextSlider-button-left {
  position: absolute;
  top: 0;
}

.slidesWrapper {
  overflow-y: hidden;
  text-align: center;
  max-width: calc(80% - 80px);
  margin: 0 auto
}

.slidesWrapper h1,
  .slidesWrapper h2 {
    font-size: clamp(1rem, 4.5vw, 44px);
    font-weight: 700;
  }

.slidesWrapper h2:first-child {
    color: #4cc6ff;
  }

.slidesWrapper h2:last-child {
    color: white;
  }

.TextSlider-button-left, .TextSlider-button-right {
  top: 30%;
  width: 40px;
  height: 40px;
  min-height: unset;
  overflow: visible;
}

.TextSlider-button-left {
  left: var(--TextSlider-button-left-position);
}

.TextSlider-button-right {
  right: var(--TextSlider-button-right-position);
}

@media (max-width: 1280px) {
    .ContactUs {
        --ContactUs-iframe-height: 350px;
    }
}

@media (max-width: 1024px) {
    .ContactUs {
        --ContactUs-aside-display: none;
        --ContactUs-display: block;
    }
}

@media (max-width: 720px) {
    
}

.ContactUs {
    text-align: center
}

.ContactUs.PaddedSection {
        padding-top: 0;
    }

.ContactUs > div {
        margin-top: 7px; /* Margin to be in-line with aside content */
        width: 670px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

.ContactUs > aside {
        display: var(--ContactUs-aside-display, block);
        color: var(--brownish-grey-two);
    }

.ContactUs iframe {
        height: var(--ContactUs-iframe-height, 450px);
        width: 100%;
    }

.ContactUs h2 {
        font-weight: var(--weight-bold);
        font-size: inherit;
        text-transform: uppercase;
        letter-spacing: .1em;
        margin: 0 0 16px;
    }

.ContactUs p {
        font-weight: var(--weight-light);
        margin: 0 0 24px;
        white-space: pre-wrap;
    }

:root {
    --ContentRevisionCycle-p-font-size: 30px;
    --ContentRevisionCycle-step-number-font-size: 52px;
    --ContentRevisionCycle-h3-font-size: 24px;
    --ContentRevisionCycle-subheading-font-size: 18px;
    --ContentRevisionCycle-round-inner-size: 360px;
    --ContentRevisionCycle-round-inner-svg-transform: rotate(8deg);
    --ContentRevisionCycle-steps-wrap-flex-direction: row;
    --ContentRevisionCycle-step-justify-content: flex-start;
    --ContentRevisionCycle-step-margin: 0 0 30px;
    --ContentRevisionCycle-step-padding: 0;
    --ContentRevisionCycle-step-width: auto;
    --ContentRevisionCycle-content-width: 156px;
    --ContentRevisionCycle-content-height: 92px;
    --ContentRevisionCycle-content-font-size: 24px;
    --ContentRevisionCycle-p-margin-bottom: 60px;
    --ContentRevisionCycle-steps-width: 600px;
}

@media (max-width: 1024px) {
    :root {
        --ContentRevisionCycle-p-font-size: 20px;
        --ContentRevisionCycle-step-number-font-size: 44px;
        --ContentRevisionCycle-h3-font-size: 22px;
        --ContentRevisionCycle-subheading-font-size: 16px;
        --ContentRevisionCycle-round-inner-size: 310px;
        --ContentRevisionCycle-round-inner-svg-transform: rotate(8deg) scale(0.8);
        --ContentRevisionCycle-steps-wrap-flex-direction: column-reverse;
        --ContentRevisionCycle-step-margin: 20px auto 0;
        --ContentRevisionCycle-step-padding: 0 5px;
        --ContentRevisionCycle-step-width: 50%;
        --ContentRevisionCycle-content-font-size: 22px;
    }
}

@media (max-width: 720px) {
    :root {
        --ContentRevisionCycle-step-padding: 0 40px;
        --ContentRevisionCycle-step-width: 100vw;
        --ContentRevisionCycle-step-justify-content: center;
        --ContentRevisionCycle-p-margin-bottom: 10px;
        --ContentRevisionCycle-steps-width: inherit;
    }
}

.ContentRevisionCycle {
    width: 1300px;
    max-width: 100%;
    margin: auto
}

.ContentRevisionCycle h1 {
        font-size: var(--h1-font-size);
        font-weight: var(--weight-bold);
        text-align: center;
        margin: 0 0 10px 0;
    }

.ContentRevisionCycle p {
        opacity: 0.5;
        font-size: var(--ContentRevisionCycle-p-font-size);
        font-weight: var(--weight-light);
        color: var(--black-three);
        text-align: center;
        margin: 0 auto var(--ContentRevisionCycle-p-margin-bottom);
        max-width: 1070px;

    }

.ContentRevisionCycle .ContentRevisionCycle-steps-wrap {
        display: flex;
        align-items: center;
        flex-direction: var(--ContentRevisionCycle-steps-wrap-flex-direction);
    }

.ContentRevisionCycle .ContentRevisionCycle-round {
        order: 1;
        margin-left: 40px;
        margin-right: 40px;
    }

.ContentRevisionCycle .ContentRevisionCycle-round-inner {
        width: var(--ContentRevisionCycle-round-inner-size);
        height: var(--ContentRevisionCycle-round-inner-size);
        position: relative;
    }

.ContentRevisionCycle .ContentRevisionCycle-round-inner > div {
        position: absolute;
        height: var(--ContentRevisionCycle-round-inner-size);
        left: calc(50% - 88px);
        transition-delay: 0.3s;
        transform: rotate(0deg);
    }

.ContentRevisionCycle .ContentRevisionCycle-round-inner .ContentRevisionCycle-content {
        left: calc(50% - 78px);
        width: var(--ContentRevisionCycle-content-width);
        height: var(--ContentRevisionCycle-content-height);
        top: calc(50% - 46px);
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

.ContentRevisionCycle .ContentRevisionCycle-round-inner .ContentRevisionCycle-content > span {
        font-size: var(--ContentRevisionCycle-content-font-size);
        font-weight: bold;
        font-stretch: condensed;
        color: var(--black-three);
        position: static;
        height: auto;
        opacity: 0;
    }

.ContentRevisionCycle.active .ContentRevisionCycle-round-inner .ContentRevisionCycle-content > span {
        animation: fadeIn 1s ease-in both;
    }

.ContentRevisionCycle .ContentRevisionCycle-round-inner > div svg {
        position: relative;
        transform: var(--ContentRevisionCycle-round-inner-svg-transform);
    }

.ContentRevisionCycle .ContentRevisionCycle-round-inner > div span {
        position: absolute;
        z-index: 2;
        font-size: 52px;
        font-weight: bold;
        color: var(--white);
        width: 100%;
        height: 105px;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 20px;
    }

.ContentRevisionCycle .ContentRevisionCycle-steps {
        display: flex;
        flex-wrap: wrap;
        max-width: var(--ContentRevisionCycle-steps-width);
    }

.ContentRevisionCycle .ContentRevisionCycle-step {
        display: flex;
        margin: var(--ContentRevisionCycle-step-margin);
        justify-content: var(--ContentRevisionCycle-step-justify-content);
        padding: var(--ContentRevisionCycle-step-padding);
        width: var(--ContentRevisionCycle-step-width);
    }

.ContentRevisionCycle  .ContentRevisionCycle-step-number {
        font-size: var(--ContentRevisionCycle-step-number-font-size);
        font-weight: var(--weight-bold);
        font-stretch: condensed;
        letter-spacing: normal;
        text-align: center;
        color: var(--dark-grey-blue);
        padding-left: 12px;
        padding-right: 12px;
    }

.ContentRevisionCycle  .ContentRevisionCycle-step-column:last-of-type {
        order: 2;
    }

.ContentRevisionCycle h3 {
        font-size: var(--ContentRevisionCycle-h3-font-size);
        font-weight: var(--weight-bold);
        color: var(--dark-grey-blue);
        margin-top: 0;
        margin-bottom: 10px;
    }

.ContentRevisionCycle h5 {
        opacity: 0.6;
        font-size: var(--ContentRevisionCycle-subheading-font-size);
        font-weight: var(--weight-bold);
        font-stretch: condensed;
        color: var(--black-three);
        margin: 0;
    }

.ContentRevisionCycle h4 {
        font-size: var(--ContentRevisionCycle-subheading-font-size);
        font-weight: var(--weight-bold);
        font-stretch: condensed;
        color: var(--black-three);
        margin-top: 0;
        margin-bottom: 10px;
    }

.ContentRevisionCycle.active .ContentRevisionCycle-round-inner > div.cycleRound-0 {
        animation: rotate-0 1500ms ease-in-out 1;
    }

@keyframes rotate-0 {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(0deg);
            opacity: 1;
        }
    }

.ContentRevisionCycle.active .ContentRevisionCycle-round-inner > div.cycleRound-1 {
        animation: rotate-1 1500ms ease-in-out 1;
    }

@keyframes rotate-1 {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(60deg);
            opacity: 1;
        }
    }

.ContentRevisionCycle.active .ContentRevisionCycle-round-inner > div.cycleRound-2 {
        animation: rotate-2 1500ms ease-in-out 1;
    }

@keyframes rotate-2 {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(120deg);
            opacity: 1;
        }
    }

.ContentRevisionCycle.active .ContentRevisionCycle-round-inner > div.cycleRound-3 {
        animation: rotate-3 1500ms ease-in-out 1;
    }

@keyframes rotate-3 {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(180deg);
            opacity: 1;
        }
    }

.ContentRevisionCycle.active .ContentRevisionCycle-round-inner > div.cycleRound-4 {
        animation: rotate-4 1500ms ease-in-out 1;
    }

@keyframes rotate-4 {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(240deg);
            opacity: 1;
        }
    }

.ContentRevisionCycle.active .ContentRevisionCycle-round-inner > div.cycleRound-5 {
        animation: rotate-5 1500ms ease-in-out 1;
    }

@keyframes rotate-5 {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(300deg);
            opacity: 1;
        }
    }

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translate3d(0, -20%, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}
:root {
	--CoronaDialog-button-wrap-flex-direction: row;
	--CoronaDialog-button-width: 48%;
	--CoronaDialog-button-margin-bottom: 0;
}

@media (max-width: 1024px) {
    :root {
		--CoronaDialog-button-wrap-flex-direction: column;
		--CoronaDialog-button-width: 100%;
		--CoronaDialog-button-margin-bottom: 20px;
    }
}

.CoronaInfo button {
        position: fixed;
        z-index: 2;
		top: 50%;
		right: -36px;
		transform: rotate(-90deg);
		background: var(--cerulean);
		color: #fff;
		padding: 13px 21px 11px;
		border: 0;
		text-transform: uppercase;
		font-weight: 600;
		cursor: pointer;
	}

.CoronaInfo a {
		color: #fff;
	}

.CoronaInfo h3 {
		text-align: center;
	}

.CoronaInfo .CoronaDialog-inner {
		padding: 20px;
		background-color: var(--cerulean);
		color: #fff;
		max-width: 500px;
		text-align: center;
    }

.CoronaInfo .CoronaDialog-button-wrap {
		display: flex;
		justify-content: space-between;
		flex-direction: var(--CoronaDialog-button-wrap-flex-direction);
		margin-top: 30px;
    }

.CoronaInfo .CoronaDialog-button {
        background-color: var(--cerulean);
        border: 2px solid #fff;
        color: var(--white);
        cursor: pointer;
        font-family: inherit;
        font-size: var(--Button-fontSize);
        font-weight: var(--weight-bold);
        letter-spacing: .05em;
        padding: var(--Button-padding);
        text-decoration: none;
        width: var(--CoronaDialog-button-width);
		transition: background .4s;
		margin-bottom: var(--CoronaDialog-button-margin-bottom);
    }

.CoronaInfo .CoronaDialog-close-button {
		background-color: transparent;
		background-image: url(/static/media/icon-cross-grey.9d9b16b7.svg);
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: 40px;
		border: 0;
		cursor: pointer;
		height: 28px;
		position: absolute;
		right: 1.25em;
		top: 1.25em;
		width: 28px;
		filter: invert(1);
	}

:root {
	--faq-padding-top: 9rem;
}
@media (--mq-width-720) {
	:root {
		--faq-padding-top: 6rem;
	}
}
.faqPadded {
	padding: var(--global-padding-top-bottom) var(--global-padding);
	padding-top: var(--faq-padding-top);
	padding-bottom: 0;
}

:root {
    --PricingTableInner-overflow: inherit;
    --PricingTable-h2-font-size: 36px;
    --PricingTable-subtitle-font-size: 28px;
}

@media (max-width: 1100px) {
    :root {
        --PricingTableInner-overflow: auto;
        --PricingTable-h2-font-size: 25px;
        --PricingTable-subtitle-font-size: 20px;
    }
}

.FbPricingTable {
    width: 1260px;
    padding: 80px 15px;
    margin: auto;
    max-width: 100%
}

.FbPricingTable h2 {
        font-weight: 700;
        font-size: var(--PricingTable-h2-font-size);
        line-height: 130%;
        text-align: center;
        color: var(--black-three);
        margin-top: 0;
        margin-bottom: 20px;
    }

.FbPricingTable .subtitle {
        font-weight: 400;
        font-size: var(--PricingTable-subtitle-font-size);
        line-height: 150%;
        text-align: center;
        color: var(--white-grey);
        margin-bottom: 40px;
    }

.FbPricingTable .PricingTableInner {
        overflow: var(--PricingTableInner-overflow);
    }

.FbPricingTable table {
        border-collapse: collapse;
        width: 100%;
        white-space: nowrap;
    }

.FbPricingTable table thead th:nth-child(2) {
        width: 200px;
    }

.FbPricingTable table tr:first-child {
        background-color: var(--cerulean);
        color: #fff;
    }

.FbPricingTable table td {
        background-color: var(--white-two);
        border: 1px solid var(--very-light-pink);
    }

.FbPricingTable table tr.highligted td {
        background: rgb(243, 244, 244);
        border-width: 0px 1px 1px 0px;
        border-style: solid;
        border-color: var(--light-grey);
    }

.FbPricingTable table tbody tr:first-child td {
        border: 1px solid var(--lighter-blue);
        background-color: var(--light-blue);
        font-weight: 500;
        font-family: Roboto;
        color: var(--black-three);
    }

.FbPricingTable .subtotal {
        border-bottom: 20px solid #fff;
    }

.FbPricingTable td,
    .FbPricingTable th {
        padding: 6px 12px;
        box-sizing: border-box;
        height: 53px;
    }

.FbPricingTable .priceWrap span:first-child {
        display: inline-block;
        min-width: 60px;
        text-align: center;
        margin-right: 5px;
    }

.FbPricingTable input {
        width: 100%;
        min-width: 200px;
        height: 40px;
        color: #9E9E9E;
        background: #FFFFFF;
        border: 1px solid var(--light-grey);
        border-radius: 2px;
        padding: 8px 30px 8px 8px;
        text-align: left;
    }

.FbPricingTable input.invalid {
        border-color: var(--brightRed);
        outline-color: var(--brightRed);
    }

.FbPricingTable .inputWrap {
        position: relative;
        margin-top: 5px;
    }

.FbPricingTable .inputWrap .info {
        position: absolute;
        right: 5px;
        top: 5px;
    }

.FbPricingTable .info .content {
        width: 240px;
        white-space: normal;
    }

.FbPricingTable select {
        width: 100%;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid var(--light-grey);
        border-radius: 2px;
    }

.FbPricingTable select:has(option:disabled:checked[hidden]) {
        color: #9E9E9E;
    }

.FbPricingTable .supportLevelInfo {
        vertical-align: middle;
        margin-left: 7px;
    }

.FbPricingTable .supportLevelInfo .content {
        width: 200px;
        white-space: normal;
    }

.FbPricingTable table tr td.highlitedField {
        background-color: var(--light-blue);
    }

.FbPricingTable .warning {
        color: var(--white-grey);
        margin-top: 10px;
    }

:root {
    --Footer-template-columns: 1fr 3fr;
    --Footer-column-gap: 20px;
    --Footer-nav-font-size: 14px;
    --Footer-nav-padding: 0 17px;
    --Footer-display: grid;
    --Footer-copy-text-align: grid;
    --Footer-nav-justify-self: end;
    --Footer-copy-justify-self: end;
    --Footer-ul-flexDirection: row;
    --Footer-nav-li-marging-bottom: 0;
    --Footer-address-marging-bottom: 0;
    --Footer-logo-marging-bottom: 27px;
    --Footer-nav-marging-bottom: 27px;
}
@media (max-width: 1024px) {
    :root {
        --Footer-template-columns: 1fr;
        --Footer-nav-justify-self: start;
        --Footer-copy-justify-self: center;
        --Footer-nav-padding: 0;
        --Footer-ul-flexDirection: column;
        --Footer-nav-li-marging-bottom: 10px;
        --Footer-address-marging-bottom: 60px;
        --Footer-nav-marging-bottom: 40px;
        --Footer-logo-marging-bottom: 40px;
    }
}
@media (max-width: 720px) {
    :root {
        --Footer-template-columns: 1fr;
        --Footer-nav-justify-self: start;
        --Footer-copy-justify-self: center;
        --Footer-nav-padding: 0;
        --Footer-ul-flexDirection: column;
        --Footer-nav-li-marging-bottom: 10px;
        --Footer-address-marging-bottom: 60px;
        --Footer-nav-marging-bottom: 40px;
        --Footer-logo-marging-bottom: 40px;
    }
}
.Footer {
    background-color: var(--black-three);
    color: white;
    display: var(--Footer-display);
    grid-template-columns: var(--Footer-template-columns);
    grid-column-gap: var(--Footer-column-gap);
    padding: 60px var(--global-padding) 80px
}
.Footer p {
        font-size: 12px;
        text-align: center;
    }
.Footer .Footer-navItems li {
            padding: var(--Footer-nav-padding);
        }
.Footer .Footer-navItems li:last-child {
            padding-right: 0;
        }
.Footer .Footer-navItems.NavItemGroup li:not(:last-child) {
            margin-bottom: var(--Footer-nav-li-marging-bottom);
        }
.Footer .Footer-navItems ul {
            flex-direction: var(--Footer-ul-flexDirection);
        }
.Footer .Footer-navItems a {
            font-size: var(--Footer-nav-font-size);
        }
.Footer .Footer-address {
        font-size: 16px;
        font-style: normal;
        color: rgb(255, 255, 255);
        margin-bottom: var(--Footer-address-marging-bottom)
    }
.Footer .Footer-address a {
            color: rgb(255, 255, 255);
            transition: .4s;
        }
.Footer .Footer-address a:hover {
            color: var(--cerulean);
        }
.Footer .Footer-nav {
        align-self: center;
        justify-self: var(--Footer-nav-justify-self);
        margin-bottom: var(--Footer-nav-marging-bottom);
    }
.Footer .Footer-copy {
        align-self: end;
        justify-self: var(--Footer-copy-justify-self);
    }
.Footer .IN-widget {
        display: block !important;
        margin-top: 15px;
    }
.Footer-logo {
    height: 30px;
    margin-bottom: var(--Footer-logo-marging-bottom);
    align-self: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 180px
}
.Footer-logo svg {
        width: 128px;
    }
:root {
    --Hero-address-fontSize: unset;
    --Hero-h1-fontSize: 76px;
    --Hero-h1-margin: 10px 0 40px;
    --Hero-h2-fontSize: 20px;
}

@media (max-width: 1024px) {
    :root {
        --Hero-h1-fontSize: 58px;
    }
}

@media (max-width: 720px) {
    :root {
        --Hero-address-fontSize: 14px;
        --Hero-h1-fontSize: 40px;
        --Hero-h1-margin: 10px 0 30px;
        --Hero-h2-fontSize: 10px;
    }
}

.Hero {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative
}

.Hero:before {
        content: '';
        background: linear-gradient(180deg, rgba(43, 56, 93, 0.0001) 21.56%, #000000 117.14%);
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }

.Hero a {
        color: inherit;
        display: block;
        text-decoration: none;
        position: relative;
        z-index: 2;
    }


:root {
  --HowOurPricingWorks-h1-font-size: var(--h1-font-size);
  --HowOurPricingWorks-p-font-size: 18px;
  --HowOurPricingWorks-grid-template-columns: repeat(3, 33%);
  --ModalView-closeIcon-color: white;
  --ModalView-closeIcon-font-size: inherit;
  --ModalView-closeIcon-font-size: inherit;
  --ModalView-track-color: var(--cerulean);
  --ModalView-thumb-color: white;
  --ModalView-closeIcon-top: 10px;
  --ModalView-closeIcon-right: 15px;
}

@media (max-width: 1024px) {
  :root {
    --ModalView-closeIcon-color: unset;
    --ModalView-track-color: inherit;
    --ModalView-thumb-color: var(--brown-grey-50);
  }
}

@media (max-width: 720px) {
  :root {
    --HowOurPricingWorks-grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  :root {
    --ModalView-closeIcon-font-size: 12px;
    --ModalView-closeIcon-top: 5px;
    --ModalView-closeIcon-right: 5px;
  }
}

.HowOurPricingWorks h1 {
    text-align: center;
    font-size: var(--HowOurPricingWorks-h1-font-size);
    color: var(--black-three);
    margin: 20px 0;
  }

.HowOurPricingWorks p {
    text-align: center;
    font-size: var(--HowOurPricingWorks-p-font-size);
    font-stretch: condensed;
    line-height: 1.44;
    color: var(--medium-grey);
  }

.HowOurPricingWorks .infoList {
    list-style-type: square;
    margin: 0;
    padding-left: 15px;
    color: var(--medium-grey)
  }

.HowOurPricingWorks .infoList > li {
      padding-bottom: 15px;
    }

.HowOurPricingWorks .listItemTitle {
    font-size: 20px;
    font-weight: 500;
  }

.HowOurPricingWorks .itemContent {
    display: block;
    font-size: 14px;
    padding: 4px 0;
    line-height: 24px;
  }

.HowOurPricingWorks .columnsWrap {
    display: grid;
    grid-template-columns: var(--HowOurPricingWorks-grid-template-columns);
    grid-gap: 30px;
  }

.HowOurPricingWorks .bookMeetingButton {
    display: block;
    width: 350px;
    max-width: 100%;
    margin: auto;
    margin-top: var(--TextImageThird-Button-margin-top);
  }

.modalView span.closeIcon {
    color: var(--ModalView-closeIcon-color);
    font-size: var(--ModalView-closeIcon-font-size);
    top: var(--ModalView-closeIcon-top) !important;
    right: var(--ModalView-closeIcon-right) !important;
  }

.modalView .contentInner::-webkit-scrollbar {
      width: 4px;
    }

.modalView .contentInner::-webkit-scrollbar-track {
      background-color: var(--ModalView-track-color);
    }

.modalView .contentInner::-webkit-scrollbar-thumb {
      border-radius: 100px;
      background-color: var(--ModalView-thumb-color);
    }

:root {
	--LearnMore-display: flex;
	--LearnMore-heading-textAlign:left;
	--LearnMore-content-align:center;
	--LearnMore-brochure-padding-right: 5rem;
}

@media (max-width:1024px) {
	:root {
		--LearnMore-display: block;
		--LearnMore-brochure-padding-right: 0;
		--LearnMore-content-align:center;
		--LearnMore-heading-textAlign: center;
	}
}

.DownloadBrochure{
	padding-top: 4rem;
	padding-bottom: 1rem;
}

.LearnMore {
	display: var(--LearnMore-display);
}

.LearnMore__brochure {
	flex-basis: 50%;
	padding-right: var(--LearnMore-brochure-padding-right);
}

.LearnMore__image {
	max-width: 100%;
	height: auto;
}

.LearnMore__content {
	display: var(--LearnMore-display);
	flex-basis: 50%;
	flex-direction: column;
	justify-content: center;
	align-items: var(--LearnMore-content-align)
}

.LearnMore__content h1 {
		color: var(--almostBlack);
		font-size: var(--h1-font-size);
		text-align: var(--LearnMore-heading-textAlign);
		margin: 15px 0;
		font-weight: var(--weight-bold);
		line-height: 1.2;
	}

.LearnMore__content a {
		width:408px;
		max-width: 100%;
		margin: .4rem auto 0
	}

.LearnMore__content a img {
			vertical-align: middle;
			margin-right: 10px;
		}


:root{
  --LinkedInMedia-Iframe-width: 47%;
  --LinkedInMedia-postsTopSection-display: flex;
  --LinkedInMedia-Iframe-display: flex;
  --LinkedInMedia-post-margin: 20px 1.5% 20px 1.5%;
  --LinkedInMedia-postsPaginationBar-justify-content: inherit;
}


@media (max-width: 1024px) {
  :root {
    --LinkedInMedia-thumbnail-maxWidth: 240px;
  }
}


@media (max-width: 720px) {
  :root {
    --LinkedInMedia-Iframe-width: 100%;
    --LinkedInMedia-postsTopSection-display: block;
    --LinkedInMedia-post-margin: 15px 0;
    --LinkedInMedia-postsPaginationBar-justify-content: center;
  }
}


.LinkedInMedia {
  padding-top: 0
}


.LinkedInMedia section.VideoSection {
    margin-top: inherit;
  }


.LinkedInMedia .postsTopSection{
    display: var(--LinkedInMedia-postsTopSection-display);
    align-items: center;
    padding-bottom: 40px;
    width: 100%;
  }


.LinkedInMedia .selectWrapper{
    margin-left: auto;
  }


.LinkedInMedia .postsPaginationBar{
    justify-content: var(--LinkedInMedia-postsPaginationBar-justify-content);
  }


.LinkedInMedia .postsWrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: baseline;
    max-width: 100%;
    min-height: 80vh
  }


.LinkedInMedia .postsWrapper .postsError{
      text-align: center;
      margin: auto;
    }


.LinkedInMedia .postsWrapper iframe.post{
      margin: var(--LinkedInMedia-post-margin);
      -moz-border-radius: 5px;
      -webkit-border-radius: 5px;
      border-radius: 5px;
      height: 600px;
      width: var(--LinkedInMedia-Iframe-width);
      -moz-box-shadow: 0px 0px 14px rgb(245, 240, 240);
      -webkit-box-shadow: 0px 0px 14px rgb(245, 240, 240);
      box-shadow: 0px 0px 14px rgb(245, 240, 240);
    }

:root {
  --news-padding-top: 9rem;
  --TextOnly-h2-fontSize: 18px;
  --headContainer-display: flex;
  --NewsContainer-inner-div-width: calc(50% - 10px);
}
@media (max-width: 720px) {
  :root {
    --news-padding-top: 6rem;
    --TextOnly-h2-fontSize: 14px;
    --headContainer-display: block;
    --NewsContainer-inner-div-width: 100%;
  }
}
.Heading {
  color: var(--dark-grey);
  font-size: var(--h1-font-size);
  font-weight: var(--weight-medium);
  margin: 0;
}
.SubHeading {
  color: var(--cerulean);
  font-size: var(--TextOnly-h2-fontSize);
  font-weight: var(--weight-medium);
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 10px;
}
.newsPadded {
  max-width: 1100px;
  margin: auto;
  padding-top: var(--news-padding-top);
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 0;
}
.NewsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between
}
.NewsContainer .postsError {
    text-align: center;
    margin: auto;
  }
.NewsContainer > div {
  width: var(--NewsContainer-inner-div-width);
}
.headContainer {
  display: var(--headContainer-display);
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: center;
}
.PaginationBar {
  width: 100%;
  display: flex;
  justify-content: center;
}

:root {
    --OurPricing-title-font-size: 52px;
    --OurPricing-content-font-size: 28px;
}

@media (max-width: 720px) {
    :root {
        --OurPricing-title-font-size: 30px;
        --OurPricing-content-font-size: 20px;
    }
}

.OurPricing {
    background: var(--white-two)
}

.OurPricing .contentWrap {
        padding: 80px 15px;
        width: 1060px;
        margin: auto;
        max-width: 100%;
    }

.OurPricing .title {
        font-weight: 700;
        font-size: var(--OurPricing-title-font-size);
        line-height: 130%;
        color: var(--black-three);
        margin-bottom: 20px;
    }

.OurPricing .content {
        font-weight: 400;
        font-size: var(--OurPricing-content-font-size);
        line-height: 150%;
        text-align: center;
        color: #666;
        margin-bottom: 20px;
    }

.OurPricing .content p {
        margin-top: 0;
        margin-bottom: 10px;
    }

.OurPricing .content a {
        color: var(--cerulean);
        font-weight: 600;
    }

.OurPricing .contentEnd {
        font-weight: 700;
        font-size: var(--OurPricing-content-font-size);
        line-height: 150%;
        text-align: center;
        color: var(--black-three);
    }

:root {
    --Portfolio-h1-font-size: var(--h1-font-size);
    --Portfolio-h2-font-size: 28px;
    --Portfolio-p-font-size: 18px;
    --Portfolio-p-max-width: 820px;
    --Portfolio-h1-margin: 0 0 20px 0;
    --Portfolio-p-margin: 0 auto 30px;
    --Portfolio-padding: 80px var(--global-padding-top-bottom) var(--global-padding);
    --Portfolio-column-width: 33.3333%;
    --Portfolio-column-inner-div-padding: 0 20px var(--global-padding-small);
    --Portfolio-img-max-width: 95%;
    --Portfolio-tablet-img-max-width: 190px;
    --Portfolio-Button-margin-top: 30px;
    --Portfolio-subItemLabel-right: -32px;
    --Portfolio-subItemLabel-left: inherit;
    --Portfolio-subItemLabel-top: -32px;
}

@media (max-width: 1280px) {
    :root {
        --Portfolio-h1-font-size: 30px;
        --Portfolio-h2-font-size: 24px;
        --Portfolio-p-font-size: 16px;
        --Portfolio-h1-margin: 0 0 14px 0;
        --Portfolio-p-margin: 0 auto 14px;
    }
}

@media (max-width: 1024px) {
    :root {
        --Portfolio-padding: 0;
        --Portfolio-column-width: 100%;
        --Portfolio-column-inner-div-padding: var(--global-padding-small) 35px;
        --Portfolio-img-max-width: calc(100% - 80px);
        --Portfolio-p-max-width: 80%;
        --Portfolio-Button-margin-top: 20px;
        --Portfolio-Button-margin-bottom: 40px;
        --Portfolio-tablet-img-max-width: var(--Portfolio-img-max-width)
    }
}

@media (max-width: 720px) {
    :root {
        --Portfolio-img-max-width: calc(100% - 30px);
        --Portfolio-Button-margin-top: 10px;
        --Portfolio-margin-bottom: inherit;
        --Portfolio-tablet-img-max-width: 65%;
    }
}

.Portfolio {
    position: relative;
    padding: var(--Portfolio-padding)
}

.Portfolio .Portfolio-container {
        width: 1240px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

.Portfolio .Row {
        flex-wrap: wrap;
    }

.Portfolio .Column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: var(--Portfolio-column-width);
        padding: var(--Portfolio-column-inner-div-padding);
        margin-bottom: var(--Portfolio-margin-bottom);
    }

.Portfolio .subItemLabel {
      font-style: normal;
      top: var(--Portfolio-subItemLabel-top);
      right: var(--Portfolio-subItemLabel-right);
      left: var(--Portfolio-subItemLabel-left);
      font-weight: 700;
      font-size: var(--Portfolio-p-font-size);
      padding: 7px 12px;
      background: #0090D3;
      border-radius: 100px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      color: #fff;
  }

.Portfolio picture {
        max-width: var(--Portfolio-img-max-width);
        width: 500px;
        margin-bottom: 30px;
        position: relative;
    }

.Portfolio .tabletImage {
        max-width: var(--Portfolio-tablet-img-max-width);
    }

.Portfolio picture img {
        width: 100%;
    }

.Portfolio .Column>div img {
        max-width: 100%;
        width: 120px;
        margin-bottom: 0;
    }

.Portfolio h1 {
        text-align: center;
        font-size: var(--Portfolio-h1-font-size);
        color: var(--black-three);
        margin: 20px 0;
    }

.Portfolio h2 {
        text-align: center;
        font-size: var(--Portfolio-h2-font-size);
        color: var(--black-three);
        margin: 0 0 20px 0;
    }

.Portfolio>p {
        color: var(--brownish-grey-two);
        font-size: var(--TextOnly-p-fontSize);
        font-weight: var(--weight-light);
        margin: 0 auto;
        text-align: center;
        max-width: var(--Portfolio-p-max-width);
    }

.Portfolio>p:last-of-type {
        margin-bottom: 40px;
    }

.Portfolio .appContent {
        text-align: center;
        font-size: var(--Portfolio-p-font-size);
        font-weight: var(--weight-light);
        font-stretch: condensed;
        line-height: 1.44;
        color: var(--brownish-grey-two);
        min-height: 100px;
        margin: var(--Portfolio-p-margin);
        width: 500px;
        max-width: 100%;
    }

.Portfolio .infoBlock {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

.Portfolio .Portfolio-Button {
        width: 350px;
        max-width: 100%;
        margin: auto;
        margin-top: var(--Portfolio-Button-margin-top);
        margin-bottom: var(--Portfolio-Button-margin-bottom);
    }

:root {
    --PricingHero-content-font-size: 28px;
}

@media (max-width: 1024px) {
    :root {
        --PricingHero-content-font-size: 20px;
    }
}

.PricingHero {
    position: relative
}

.PricingHero:before {
        content: "";
        position: absolute;
        inset: 0;
        background: rgba(31, 31, 31, 0.8);
    }

.PricingHero .contentInner {
        position: relative;
        z-index: 2;
    }

.PricingHero h1 {
        font-family: 'Roboto Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 52px;
        margin-bottom: 20px;
        color: var(--bright-cyan);
        margin-top: 150px;
    }

.PricingHero .content {
        font-size: var(--PricingHero-content-font-size);
        font-weight: 400;
        margin-bottom: 40px;
        max-width: 1200px;
        line-height: 1.5;
    }

.PricingHero .PricingHero-button {
        max-width: 300px;
        display: inline-block;
        text-align: center;
    }

:root {
    --PricingTableInner-overflow: inherit;
    --PricingTable-h2-font-size: 36px;
    --PricingTable-subtitle-font-size: 28px;
}

@media (max-width: 1100px) {
    :root {
        --PricingTableInner-overflow: auto;
        --PricingTable-h2-font-size: 25px;
        --PricingTable-subtitle-font-size: 20px;
    }
}

.PricingTable {
    width: 1260px;
    padding: 80px 15px;
    margin: auto;
    max-width: 100%
}

.PricingTable h2 {
        font-weight: 700;
        font-size: var(--PricingTable-h2-font-size);
        line-height: 130%;
        text-align: center;
        color: var(--black-three);
        margin-top: 0;
        margin-bottom: 20px;
    }

.PricingTable .subtitle {
        font-weight: 400;
        font-size: var(--PricingTable-subtitle-font-size);
        line-height: 150%;
        text-align: center;
        color: var(--white-grey);
        margin-bottom: 40px;
    }

.PricingTable .PricingTableInner {
        overflow: var(--PricingTableInner-overflow);
    }

.PricingTable table {
        border-collapse: collapse;
        width: 100%;
        white-space: nowrap;
    }

.PricingTable table thead th:nth-child(2) {
        width: 200px;
    }

.PricingTable table tr:first-child {
        background-color: var(--cerulean);
        color: #fff;
    }

.PricingTable table td {
        background-color: var(--white-two);
        border: 1px solid var(--very-light-pink);
    }

.PricingTable table tr.highligted td {
        background: rgb(243, 244, 244);
        border-width: 0px 1px 1px 0px;
        border-style: solid;
        border-color: var(--light-grey);
    }

.PricingTable table tbody tr:first-child td {
        border: 1px solid var(--lighter-blue);
        background-color: var(--light-blue);
        font-weight: 500;
        font-family: Roboto;
        color: var(--black-three);
    }

.PricingTable .subtotal {
        border-bottom: 20px solid #fff;
    }

.PricingTable td,
    .PricingTable th {
        padding: 6px 12px;
        box-sizing: border-box;
        height: 53px;
    }

.PricingTable .priceWrap span:first-child {
        display: inline-block;
        min-width: 60px;
        text-align: center;
        margin-right: 5px;
    }

.PricingTable input {
        width: 100%;
        min-width: 140px;
        height: 40px;
        color: #9E9E9E;
        background: #FFFFFF;
        border: 1px solid var(--light-grey);
        border-radius: 2px;
        padding: 8px 30px 8px 8px;
        text-align: right;
    }

.PricingTable input.invalid {
        border-color: var(--brightRed);
        outline-color: var(--brightRed);
    }

.PricingTable .inputWrap {
        position: relative;
    }

.PricingTable .inputWrap .info {
        position: absolute;
        right: 5px;
        top: 5px;
    }

.PricingTable .info .content {
        width: 240px;
        white-space: normal;
    }

.PricingTable select {
        width: 100%;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid var(--light-grey);
        border-radius: 2px;
    }

.PricingTable select:has(option:disabled:checked[hidden]) {
        color: #9E9E9E;
    }

.PricingTable .supportLevelInfo {
        vertical-align: middle;
        margin-left: 7px;
    }

.PricingTable .supportLevelInfo .content {
        width: 200px;
        white-space: normal;
    }

.PricingTable table tr td.highlitedField {
        background-color: var(--light-blue);
    }

.PricingTable .maxExceded {
        position: fixed;
        inset: 0;
        transform: none;
        display: flex !important;
        align-items: center;
        justify-content: center;
        background-color: rgba(24, 24, 24, 0.7);
        width: 100%;
        z-index: 10;
    }

.PricingTable .maxExceded .content {
        padding: 30px 30px 20px;
        width: 500px;
        max-width: 90%;
        position: relative;
        background: var(--cerulean);
        color: #fff;
        font-size: 20px;
        line-height: 1.5;
    }

.PricingTable .maxExceded .close {
        position: absolute;
        top: -25px;
        right: 0px;
        color: #fff;
        cursor: pointer;
        width: 20px;
        height: 20px;
        transform: rotate(45deg);
        display: block;
    }

.PricingTable .maxExceded .close::before,
    .PricingTable .maxExceded .close::after {
        width: 20px;
        height: 3px;
        background: #fff;
        content: "";
        transition: transform 0.1s;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
    }

.PricingTable .maxExceded .close::after {
        transform: rotate(90deg);
    }

.PricingTable .maxExceded a {
        color: #fff;
    }

.PricingTable .warning {
        color: var(--white-grey);
        margin-top: 10px;
    }

:root {
	--QuotesSection-quote-cite-fontSize: unset;
	--QuotesSection-quote-footer-marginTop: 74px;
	--QuotesSection-quote-paddingTop: 60px;
	--QuotesSection-quote-span-fontSize: 32px;
	--QuotesSection-slidePaginationWrap-display: none;
}

@media (max-width: 720px) {
	:root {
		--QuotesSection-quote-cite-fontSize: 14px;
		--QuotesSection-quote-footer-marginTop: 26px;
		--QuotesSection-quote-paddingTop: 24px;
		--QuotesSection-quote-span-fontSize: 20px;
		--QuotesSection-slidePaginationWrap-display: block;
	}

	.QuotesSection-button-right,
	.QuotesSection-button-left {
		display: none;
	}
}

.QuotesSection {
	background-color: var(--cerulean);
	color: white;
	position: relative;
	text-align: center;
	padding-left: 0;
	padding-right: 0
}

.QuotesSection h1 {
		font-size: var(--h1-font-size);
		font-weight: var(--weight-bold);
		margin: 0;
		line-height: 1.4;
	}

.QuotesSection .slidePaginationWrap{
		display: var(--QuotesSection-slidePaginationWrap-display);
	}

.QuotesSection-quote {
	display: flex;
	flex-direction: column;
	font-weight: var(--weight-light);
	height: 100%;
	justify-content: center;
	letter-spacing: 1px;
	margin: 0;
	padding-left: var(--global-padding);
	padding-right: var(--global-padding);
	padding-top: var(--QuotesSection-quote-paddingTop);
	pointer-events: all
}

.QuotesSection-quote span {
		display: block;
		font-size: var(--QuotesSection-quote-span-fontSize);
		line-height: 1.5
	}

.QuotesSection-quote span::before {
			content: '“';
		}

.QuotesSection-quote span::after {
			content: '”';
		}

.QuotesSection-quote footer {
		margin-top: var(--QuotesSection-quote-footer-marginTop);
	}

.QuotesSection-quote cite {
		font-style: normal;
		font-size: var(--QuotesSection-quote-cite-fontSize)
	}

.QuotesSection-quote cite::before {
			content: '—';
			margin-right: .5em;
		}

.QuotesSection-quote img {
		display: block;
		margin: 1.4rem auto 0;
	}

.QuotesSection-button-right,
.QuotesSection-button-left {
	position: absolute;
	top: 0;
}

.QuotesSection-button-left {
	left: 0;
}

.QuotesSection-button-right {
	right: 0;
}

:root {
    --Table-heading-font-size: 36px;
    --Table-first-child-width: 285px;
}

@media (max-width: 1100px) {
    :root {
        --Table-heading-font-size: 25px;
    }
}

@media (max-width: 720px) {
    :root {
        --Table-first-child-width: inherit;
    }
}

.Table .heading {
        font-weight: 700;
        font-size: var(--Table-heading-font-size);
        line-height: 130%;
        text-align: center;
        color: var(--black-three);
        margin-top: 0;
    }

.Table .tableInner {
        overflow: auto;
    }

.Table table {
        width: 100%;
        border-collapse: collapse;
    }

.Table td {
        padding: 15px;
        background: var(--white-two);
        border-bottom: 1px solid var(--light-grey);
        vertical-align: top;
    }

.Table td:first-child {
        font-weight: 500;
        font-family: Roboto;
        width: var(--Table-first-child-width);
        background-color: var(--light-blue);
        border-bottom: 1px solid var(--lighter-blue);
    }

.Table table table {
        font-size: 13px;
        margin-bottom: 8px;
    }

.Table .afterText {
        font-size: 13px;
    }

.Table table table td:first-child {
        width: inherit;
    }

.Table table table tr:first-child td {
        background-color: var(--light-blue);
        border-bottom: 1px solid var(--lighter-blue);
        text-align: center;
        font-weight: bold;
    }

.Table a {
        color: var(--cerulean);
    }

:root {
    --TextImage-image-width: 60vw;
    --TextImage-ul-fontSize: 18px;
    --TextImage-ul-margin: 1em 0 2.25em;
    --TextImage-text-width: 50vw;
    --TextImage-min-height: 94vh;
}

@media (max-width: 1500px) {
    :root {
        --TextImage-min-height: 74vh;
    }
}

@media (max-width: 1024px) {
    :root {
        --TextImage-image-width: 100%;
        --TextImage-text-width: 100%;
        --TextImage-min-height: inherit;
    }

    .TextImage {
        padding: 0;
    }
}

@media (max-width: 720px) {
    :root {
        --TextImage-ul-fontSize: 16px;
        --TextImage-ul-margin: 1em 0 1.875em;
    }
}

.TextImage-image {
    position: absolute;
    background-color: var(--color-lightGrey);
    height: 100%;
    width: var(--TextImage-image-width);
    right: 0;
    top: 0;
}

.TextImage {
    margin-top: var(--global-padding-top-bottom);
    position: relative;
    padding-left: 0;
    padding-right: 0;
    z-index: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: var(--TextImage-min-height)
}

.TextImage.imageToRight {
        flex-direction: row-reverse;
    }

.TextImage.imageToRight .TextImage-image {
        right: inherit;
        left: 0;
    }

.TextImage-text {
    padding: var(--global-padding-top-bottom) var(--global-padding);

    /* padding-right: calc(var(--global-padding) + 10vw); */
    position: relative;
    width: var(--TextImage-text-width);
    z-index: 1;
    height: 100%
}

.TextImage-text::before {
        background-color: var(--black-three-90);
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }

.TextImage-text h1 {
        color: var(--bright-cyan);
        font-size: var(--h1-font-size);
        font-weight: var(--weight-bold);
        margin: 0 0 20px;
    }

.TextImage-text ul {
        color: white;
        font-size: var(--TextImage-ul-fontSize);
        font-weight: var(--weight-light);
        margin: var(--TextImage-ul-margin);
        list-style: none;
        padding-left: 0;
    }

.TextImage-text li {
        line-height: 1.44;
    }

.TextImage-text .Button {
        width: 215px;
        display: inline-block;
        text-align: center;
    }

:root {
    --TextImageSecond-button-width: 320px;
    --TextImageSecond-display: grid;
    --TextImageSecond-gridTemplate-columns: 2fr 1.5fr;
    --TextImageSecond-gridTemplate-reversed: 1.5fr 2fr;
    --TextImageSecond-h1-margin: 0 0 20px;
    --TextImageSecond-h1-text-align: inherit;
    --TextImageSecond-h2-fontSize: 20px;
    --TextImageSecond-h2-fontSize-grey: 28px;
    --TextImageSecond-h2-textAlign: left;
    --TextImageSecond-h2-margin: 0 0 20px;
    --TextImageSecond-image-marginBottom: unset;
    --TextImageSecond-image-marginTop: unset;
    --TextImageSecond-p-fontSize: 20px;
    --TextImageSecond-p-margin: 0 0 40px;
    --TextImageSecond-a-margin: 15px 0 0 0;
}

@media (max-width: 1280px) {
    :root {
        --TextImageSecond-gridTemplate-columns: 1fr 1fr;
        --TextImageSecond-h1-margin: 0 0 32px;
    }
}

@media (max-width: 1024px) {
    :root {
        --TextImageSecond-image-marginTop: 30px;
        --TextImageSecond-display: flex;
        --TextImageSecond-h1-margin: 0 0 32px;
        --TextImageSecond-h1-text-align: center;
        --TextImageSecond-a-margin: 15px auto 0;
    }
}

@media (max-width: 720px) {
    :root {
        --TextImageSecond-h2-fontSize: 14px;
        --TextImageSecond-h1-margin: 0 0 10px;
        --TextImageSecond-button-width: 240px;
        --TextImageSecond-image-marginBottom: 0;
        --TextImageSecond-image-marginTop: 30px;
        --TextImageSecond-p-fontSize: 14px;
        --TextImageSecond-h2-fontSize-grey: 20px;
        --TextImageSecond-h2-textAlign: center;
        --TextImageSecond-p-margin: 0 0 30px;
        --TextImageSecond-h2-margin: 0 0 10px;
        --TextImageSecond-display: flex;
        --TextImageSecond-display-reverse: column-reverse;
    }
}

.TextImageSecond {
    display: var(--TextImageSecond-display);
    height: 100%;
    column-gap: 30px;
    grid-template-areas: 'content image';
    grid-template-columns: var(--TextImageSecond-gridTemplate-columns);
    flex-direction: column
}

.TextImageSecond h1 {
        color: var(--color-darkGrey);
        font-size: var(--h1-font-size);
        font-weight: var(--weight-bold);
        margin: var(--TextImageSecond-h1-margin);
        text-align: var(--TextImageSecond-h1-text-align)
    }

.TextImageSecond h2 {
        color: var(--cerulean);
        font-size: var(--TextImageSecond-h2-fontSize);
        font-weight: var(--weight-bold);
        margin: var(--TextImageSecond-h2-margin);
        text-transform: uppercase;
    }

.TextImageSecond h2.grey {
        color: var(--medium-grey);
        font-size: var(--TextImageSecond-h2-fontSize-grey);
        font-weight: var(--weight-light);
        margin-bottom: 30px;
        text-align: var(--TextImageSecond-h2-textAlign);
    }

.TextImageSecond p {
        color: var(--brownish-grey-two);
        font-weight: var(--weight-light);
        font-size: var(--TextImageSecond-p-fontSize);
        line-height: 1.4;
        margin: var(--TextImageSecond-p-margin);
        margin-bottom: 15px;
    }

.TextImageSecond a {
        color: var(--cerulean);
    }

.TextImageSecond .Button {
        color: #fff;
    }

.TextImageSecond .TextImageSecond-button {
        margin: var(--TextImageSecond-a-margin);
        text-align: center;
        width: 350px;
        max-width: 100%;
    }

.TextImageSecond .underButtonText{
        display: inline-block;
        margin: var(--TextImageSecond-a-margin);
        color: var(--brown-grey);
    }

.TextImageSecond_reversed{
	grid-template-areas: 'image content';
	grid-template-columns: var(--TextImageSecond-gridTemplate-reversed);
}

.TextImageSecond-image {
    grid-area: image;
    padding-top: 90%;
    margin-bottom: var(--TextImageSecond-image-marginBottom);
    margin-top: var(--TextImageSecond-image-marginTop);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;
    order: 1;
}

.TextImageSecond-content {
    display: flex;
    flex-direction: column;
    grid-area: content;
    justify-content: center;
}

:root {
    --TextImageThird-h1-margin: 0 0 20px 0;
    --TextImageThird-Grid-template-columns: repeat(3, 1fr);
    --TextImageThird-images-container-width: 80%;
    --TextImageThird-p-font-size: 20px;
    --TextImageThird-p-margin: 0 auto 40px auto;
    --TextImageThird-Button-margin-top: 40px;
}

@media (max-width: 720px) {
    :root {
        --TextImageThird-h1-margin: 0 0 14px 0;
        --TextImageThird-Grid-template-columns: repeat(2, 1fr);
        --TextImageThird-images-container-width: 100%;
        --TextImageThird-p-font-size: 16px;
        --TextImageThird-p-margin: 0 auto 18px auto;
        --TextImageThird-Button-margin-top: 20px;
    }
        .TextImageThird div.fourColumnGrid {
            grid-template-columns: var(--TextImageThird-Grid-template-columns);
        }
}

@media (max-width: 480px) {
    :root {
        --TextImageThird-Grid-template-columns: 1fr;
    }
}

.TextImageThird {
    text-align: center
}

.TextImageThird h1 {
        font-size: var(--h1-font-size);
        font-weight: var(--weight-bold);
        margin: var(--TextImageThird-h1-margin);
    }

.TextImageThird p {
        margin: var(--TextImageThird-p-margin);
        font-size: var(--TextImageThird-p-font-size);
        font-weight: var(--weight-light);
        width: 945px;
        max-width: 100%;
        color: var(--brownish-grey-two);
        line-height: 1.4;
    }

.TextImageThird img {
        max-width: 100%;
    }

.TextImageThird p a {
        color: var(--cerulean);
    }

.TextImageThird .TextImageThird-images {
        display: grid;
        max-width: var(--TextImageThird-images-container-width);
        grid-template-columns: var(--TextImageThird-Grid-template-columns);
        align-items: center;
        margin: auto
    }

.TextImageThird .TextImageThird-images > div{
            padding: 20px;
        }

.TextImageThird .fourColumnGrid{
        grid-template-columns: repeat(4, 1fr);
    }

.TextImageThird .TextImageThird-Button {
        width: 350px;
        max-width: 100%;
        margin: auto;
        margin-top: var(--TextImageThird-Button-margin-top);
    }

:root {
    --VideoBoxWithText-flex-direction: row;
    --VideoBoxWithText-flex-direction-reversed: row-reverse;
    --VideoBoxWithText-padding-top: var(--global-padding-top-bottom);
    --VideoBoxWithText-content-width: 460px;
    --VideoBoxWithText-content-text-align: left;
    --VideoBoxWithText-h3-font-size: 52px;
    --VideoBoxWithText-videoWrap-width: calc(100% - 580px);
    --VideoBoxWithText-videoWrap-max-width: 60%;
    --VideoBoxWithText-videoWrap-margin-top: 0;
    --VideoBoxWithText-content-fontSize: 25px;
    --VideoBoxWithText-h3-margin-bottom: 20px;
    --VideoBoxWithText-contentWrap-margin-right: 100px;
    --VideoBoxWithText-contentWrap-margin-left-reversed: 100px;
    --VideoBoxWithText-contentWrap-button-margin: 30px 0 0;
}

@media (max-width: 1440px) {
    :root {
        --VideoBoxWithText-content-width: 380px;
        --VideoBoxWithText-videoWrap-width: calc(100% - 480px);
    }
}

@media (max-width: 1200px) {
    :root {
        --VideoBoxWithText-content-width: 300px;
        --VideoBoxWithText-videoWrap-width: calc(100% - 350px);
        --VideoBoxWithText-contentWrap-margin-right: 50px;
        --VideoBoxWithText-contentWrap-margin-left-reversed: 50px;
        --VideoBoxWithText-h3-font-size: 40px;
    }
}

@media (max-width: 1024px) {
    :root {
        --VideoBoxWithText-flex-direction: column;
        --VideoBoxWithText-flex-direction-reversed: column;
        --VideoBoxWithText-content-width: 100%;
        --VideoBoxWithText-content-text-align: center;
        --VideoBoxWithText-h3-font-size: 30px;
        --VideoBoxWithText-videoWrap-width: 100%;
        --VideoBoxWithText-videoWrap-max-width: 100%;
        --VideoBoxWithText-videoWrap-margin-top: 30px;
        --VideoBoxWithText-content-fontSize: 20px;
        --VideoBoxWithText-h3-margin-bottom: 10px;
        --VideoBoxWithText-contentWrap-margin-right: 0;
        --VideoBoxWithText-contentWrap-margin-left-reversed: 0;
        --VideoBoxWithText-contentWrap-button-margin: 30px auto 0;
    }
}

@media (max-width: 729px) {
    :root {
        --VideoBoxWithText-videoWrap-margin-top: 20px;
        --VideoBoxWithText-h3-margin-bottom: 5px;
        --VideoBoxWithText-padding-top: 30px;
    }
}

.VideoBoxWithText {
    padding-top: var(--VideoBoxWithText-padding-top)
}

.VideoBoxWithText .innerWrap {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: var(--VideoBoxWithText-flex-direction);
    }

.VideoBoxWithText.reversed .innerWrap {
        flex-direction: var(--VideoBoxWithText-flex-direction-reversed);
    }

.VideoBoxWithText.reversed .contentWrap {
        margin-left: var(--VideoBoxWithText-contentWrap-margin-left-reversed);
        margin-right: 0;
    }

.VideoBoxWithText .contentWrap {
        width: var(--VideoBoxWithText-content-width);
        text-align: var(--VideoBoxWithText-content-text-align);
        margin-right: var(--VideoBoxWithText-contentWrap-margin-right)
    }

.VideoBoxWithText .contentWrap .heading {
            font-weight: 700;
            font-size: var(--VideoBoxWithText-h3-font-size);
            line-height: 1.3;
            color: var(--black-three);
            margin-bottom: var(--VideoBoxWithText-h3-margin-bottom);
            margin-top: 0;
        }

.VideoBoxWithText .contentWrap .content {
            font-weight: 300;
            font-size: var(--VideoBoxWithText-content-fontSize);
            line-height: 1.5;
            color: var(--medium-grey);
        }

.VideoBoxWithText .Button {
        margin: var(--VideoBoxWithText-contentWrap-button-margin);
        text-align: center;
        width: 350px;
        max-width: 100%;
        display: block;
    }

.VideoBoxWithText .videoWrap {
        width: var(--VideoBoxWithText-videoWrap-width);
        margin-top: var(--VideoBoxWithText-videoWrap-margin-top);
        max-width: var(--VideoBoxWithText-videoWrap-max-width)
    }

.VideoBoxWithText .videoWrap img {
            width: 100%;
            display: block;
            border-radius: 8px;
        }

.VideoBoxWithText .videoWrap .video {
            position: relative;
            border: 12px solid var(--bright-cyan);
            background-color: var(--bright-cyan);
            border-radius: 16px;
        }

.VideoBoxWithText .videoWrap .VideoButton {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2
        }

.VideoBoxWithText .videoWrap .VideoButton button {
                font-size: 10px;
            }

:root {
    --VideoSection-fontSize: 28px;
    --VideoSection-heightRatio: 40%;
    --VideoSection-margin-top: 80px;
    --VideoSection-h1-margin: 0 0 10px 0;
    --VideoSection-p-margin-bottom: 20px;
}

@media (max-width: 720px) {
    :root {
        --VideoSection-fontSize: 13px;
        --VideoSection-margin-top: 40px;
        --VideoSection-heightRatio: 56.25%;
        --VideoSection-p-margin-bottom: 20px;
    }
}

.VideoSection {
    color: white;
    margin-top: var(--VideoSection-margin-top)
}

.VideoSection .VideoSection-wrap {
        padding-bottom: var(--VideoSection-heightRatio);
        background-size: cover;
        background-position: center center;
        position: relative;
        z-index: 0;
        min-height: 87vh
    }

.VideoSection .VideoSection-wrap::after {
            background-color: var(--black-three);
            content: '';
            height: 100%;
            left: 0;
            opacity: .7;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: -1;
        }

.VideoSection h1.VideoSection-heading {
        text-align: center;
        font-size: var(--h1-font-size);
        font-weight: var(--weight-bold);
        margin: var(--VideoSection-h1-margin);
        color: var(--black-three);
        padding: 0 var(--global-padding);
    }

.VideoSection h1.VideoSection-play-wrap {
        align-content: center;
        grid-column-gap: 25px;
        display: grid;
        font-weight: var(--weight-light);
        grid-template-areas: 'span1 span3 span2';
        grid-template-columns: 1fr min-content 1fr;
        height: 100%;
        left: 0;
        margin: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

.VideoSection span {
        align-items: center;
        display: flex;
        font-size: var(--VideoSection-fontSize);
    }

.VideoSection span:nth-of-type(1) {
        grid-area: span1;
        justify-content: flex-end;
    }

.VideoSection span:nth-of-type(2) {
        grid-area: span2;
    }

.VideoSection span:nth-of-type(3) {
        grid-area: span3;
    }

.VideoSection p {
        font-size: var(--VideoSection-fontSize);
        font-weight: var(--weight-light);
        color: var(--brownish-grey-two);
        text-align: center;
        margin: 0 auto var(--VideoSection-p-margin-bottom);
        max-width: 1070px;

    }

.VideoSection-player {
    position: absolute;
    top: 0;
    left: 0;
}

.WingBoxLearnMore {
    padding-top: 40px;
    padding-bottom: 40px
}
.WingBoxLearnMore a {
        max-width: 100%;
        width: 408px;
        margin: auto
    }
.WingBoxLearnMore a img {
            vertical-align: middle;
            margin-right: 10px;
        }


:root {
    --TextImageSecond-image-position: relative;
    --TextOnly-p-max-width: 70%;
    --TextImageSecond-width: 116%;
    --TextImageSecond-left: 20px;
    --TextOnly-p-max-width: 820px;
    --TextImageSecond-button-margin: 30px 0;
}

@media (max-width: 1024px) {
    :root {
        --TextImageSecond-image-position: static;
        --TextImageSecond-h1-margin: 0 0 32px;
        --TextOnly-p-max-width: 100%;
        --TextImageSecond-width: auto;
        --TextImageSecond-top: unset;
        --TextImageSecond-left: unset;
        --TextImageSecond-button-margin: 20px auto;
    }
}

.textOnly{
    padding-bottom: 0;
    text-align: center

}

.textOnly p {
        max-width: var(--TextOnly-p-max-width);
        margin: 0 auto;
        line-height: 150%;
    }

.AboutAndPartnersView {
    background-color: var(--white)
}

.AboutAndPartnersView .TextImageSecond-image {
        background-image: url(/static/media/people_in_office.0436f9e7.png);
        padding-top: 90%;
        position: var(--TextImageSecond-image-position);
        width: var(--TextImageSecond-width);
        top: 5px;
        left: var(--TextImageSecond-left);
    }

.AboutAndPartnersView .TextImageSecond-content{
        justify-content: flex-start;
    }

.AboutAndPartnersView .TextImageSecond-button{
        margin: var(--TextImageSecond-button-margin);
    }

.AllToolsInOneSpaceView {
    background-color: var(--white-two);
    padding-bottom: 30px;
    padding-bottom: 30px
}
.AllToolsInOneSpaceView .TextImageSecond-image {
        background-image: url(/static/media/swiss-knife.bea5dd29.png);
        padding-top: 80%;
    }
.AllToolsInOneSpaceView p {
        line-height: 1;
    }

.AppNotificationView {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 2
}

.AppNotificationView a {
		color: white;
	}

.AppNotificationView-notification:not(:first-child) {
		border-top: 1px solid white;
	}

.AppointmentBookingView {

}

:root {
    --AviationEdocsView-padding-bottom: var(--global-padding-small);
    --AviationEdocsView-p-font-size: 30px;
}

@media (max-width: 720px) {
    :root {
        --AviationEdocsView-padding-bottom: 20px;
        --AviationEdocsView-p-font-size: 20px;
    }
}

.AviationEdocsView {
    text-align: center;
    padding-top: 0;
    padding-bottom: var(--AviationEdocsView-padding-bottom)
}

.AviationEdocsView h1 {
        line-height: 1;
        margin-bottom: 0;
    }

.AviationEdocsView p {
        margin: auto;
        opacity: 0.5;
        font-size: var(--AviationEdocsView-p-font-size);
        color: var(--black-three);
    }

.AviationVideoView {
    background-image: url(/static/media/video-watch-taggis.4f373ca4.jpg);
}

.BlueHeadingView {

}

.textOnlyStyle {
  text-align: center
}
.textOnlyStyle p {
    margin: 0 auto;
    line-height: 150%;
  }

.DocBrowserWebPortalView {
    background-image: url(/static/media/group_19_2.25b7858d.jpg);
    background-size: cover;
}

.FlightBriefingAppView {
    background-image: url(/static/media/Flight_Briefing_solutions_image.ff02ae7d.jpg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.FooterView {

}


:root {
    --TextImageSecond-image-position: relative;
    --TextImageSecond-top: 40px;
    --TextImageSecond-left: 40px;
}

@media (max-width: 1024px) {
    :root {
        --TextImageSecond-image-position: static;
        --TextImageSecond-top: unset;
        --TextImageSecond-left: unset;
    }
}

.FreeTrialView {
    padding-bottom: 0
}

.FreeTrialView .TextImageSecond-image {
        background-image: url(/static/media/time_to_cut_cost.0f775376.png);
        padding-top: 80%;
        position: var(--TextImageSecond-image-position);
        top: var(--TextImageSecond-top);
        left: var(--TextImageSecond-left);
    }

:root {
    --GetInTouch-padding-top: 80px;
    --GetInTouch-padding-bottom: 14px;
}

.GetInTouch {
    padding-top: var(--global-padding-top-bottom);
    padding-bottom: var(--GetInTouch-padding-bottom);
    text-align: center
}

.GetInTouch h1 {
        margin-bottom: 20px;
    }

.GetInTouch p {
        max-width: 100%;
    }

:root {
    --Header-before-transition: opacity 0.2s ease-out;
}

@media (max-width: 720px) {
    :root {
        --Header-before-transition: transform 0.2s 0.15s ease-out, opacity 0.2s ease-out;
    }
}

.Header {
    height: 100vh;
    pointer-events: none;
    z-index: 4
}

.Header::before {
        background:
            linear-gradient(to bottom, var(--almostBlack-alpha80) 0%, var(--almostBlack-alpha95) 100%);
        content: '';
        height: 100%;
        transform: translateY(calc(-100% + var(--global-header-height)));
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        transition: var(--Header-before-transition);
        width: 100%;
        z-index: -1;
    }

.Header.backdrop::before {
        opacity: 1;
    }

.Header.backdropFull::before {
        height: 100%;
        transform: translateY(0);
        transition: transform 0.2s ease-out, opacity 0.2s ease-out;
    }

.Header-wrapper {
    align-items: center;
    background:
        linear-gradient(to bottom, var(--almostBlack-alpha45) 0%, var(--almostBlack-alpha0) 100%);
    display: flex;
    height: var(--global-header-height);
    justify-content: space-between;
    padding: 0 var(--global-padding);
    pointer-events: all
}

.Header-wrapper .Header-navItems {
        flex-grow: 1;
        margin-left: 44px;
        max-width: 800px;
    }

.Header-logo {
    transform: translateY(-1px);
    flex-shrink: 0;
}

.Header-mobileDraw {
    bottom: 0;
    display: flex;
    margin-top: var(--global-header-height);
    opacity: 0;
    overflow-y: auto;
    position: absolute;
    top: 0;
    transform: translateY(-30px);
    transition: transform 0.5s ease-out, opacity 0.25s ease-out;
    width: 100%;
    z-index: -1
}

.Header-mobileDraw.visible {
        opacity: 1;
        pointer-events: all;
        transform: translateY(0);
        transition: transform 0.5s 0.1s ease-out, opacity 0.5s 0.1s ease-out;
    }

.Header-mobileDraw .Header-navItems {
        width: calc(100% - var(--global-padding));
        color: var(--bright-cyan);
        padding-top: 25px;
        margin: auto auto 125px var(--global-padding);
    }

.HeaderView {
    color: white;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
}

:root {
    --HeroView-backgroundUrl: url(/static/media/Flight_Briefing_solutions_image_with-label.f2590c08.jpg);
    --HeroView-background-position: center center;
}

@media (max-width: 1024px) {
    :root {
        --HeroView-background-position: center;
    }
}

@media (max-width: 720px) {
    :root {
        --HeroView-backgroundUrl: url(/static/media/Flight_Briefing_solutions_image_with-label.f2590c08.jpg);
    }
}

.HeroView {
    background-image: var(--HeroView-backgroundUrl);
    background-position: var(--HeroView-background-position);
    background-size: cover;
    color: white;
    padding-bottom: 60px;
}

.HowOurPricingWorksView {

}

.iFlyThereVideoView {
    background-image: url(/static/media/ifly-there.dbce99a3.jpg);
}

.IReFormVideoView {
    background-image: url(/static/media/iReForm.13ba31fa.png);
}

.IreformAppView .contentWrap .content {
    font-size: 20px;
}

.LearnMoreView{
    background-color: var(--white-two);
}



    .LinkedInMediaView .socialMediaTextOnly {
       text-align: center
    }
.LinkedInMediaView .socialMediaTextOnly p {
            text-align: center;
            margin: auto;
        }


  .ModalView .overlay {
    background-color: rgba(0, 0, 0, 0.44);
    position: fixed;
    inset: 0px;
    z-index: 99;
    overflow: hidden;
  }
.ModalView .content {
    background-color: white;
    box-shadow: rgba(14, 18, 22, 0.35) 0px 10px 38px -10px, rgba(14, 18, 22, 0.2) 0px 10px 20px -15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    max-width: 1240px;
    z-index: 101
  }
.ModalView .content .contentInner{
      position: relative;
    }
.ModalView .content .contentInner .closeIcon{
        position: fixed;
        top: 10px;
        right: 15px;
        cursor: pointer;
        user-select: none;
        background: inherit;
        padding: 5px;
      }
.ModalView .content .contentInner > div{
            padding: 0 !important;
        }
.ModalView .content .contentInner{

        max-height: 94vh;
        overflow: hidden;
        overflow-y: auto;
    }

.NewView {
	/* background-color: var(--white-two); */
}
.NewView .TextImageSecond-image {
		 background-image: url(/static/media/new-laptop.7c36fdcf.jpg);
		 padding-top: 80%;
		background-position: top 30px center;
	}


    .OurGrowingFleetView p {
        width: 655px;
    }

.ourPartnersView {
    background-color: var(--white-two);
}

:root {
    --PricingHeroView-backgroundUrl: url(/static/media/hero_pricing_image.22dbcaab.png);
    --PricingHeroView-background-position: inherit;
}

@media (max-width: 1024px) {
    :root {
        --PricingHeroView-background-position: center;
    }
}

.PricingHeroView {
    background-image: var(--PricingHeroView-backgroundUrl);
    background-position: var(--PricingHeroView-background-position);
    background-size: cover;
    color: white;
    padding-bottom: 60px;
}

.PricingInfo {
    width: 1260px;
    margin: auto;
    padding: 0 15px;
    box-sizing: border-box;
    max-width: 100%;
}

:root {
    --Table-last-child-white-space: nowrap;
}
@media (max-width: 720px) {
    :root {
        --Table-last-child-white-space: inherit;
    }
}
.PricingOtherProducts {
    width: 1260px;
    margin: auto;
    padding: 0 15px;
    box-sizing: border-box;
    max-width: 100%
}
.PricingOtherProducts table td:nth-child(2) {
        background: #F3F4F4;
    }
.PricingOtherProducts table td:last-child {
        white-space: var(--Table-last-child-white-space);
    }

.ProductSuiteVideoView {

}

.RevisionCycleView {

}

.TaggisView {
    background-image: url(/static/media/taggis_laptop.90e04db9.jpg);
    background-size: cover;
}

.WhatWeDoView {

}

.WhoWeAreView {
    background-color: var(--white-two)
}
.WhoWeAreView .TextImageSecond-image {
        background-image: url(/static/media/who-we-are.5bd86a7a.png);
        padding-top: 80%;
    }

:root {
  --cerulean: rgb(0, 144, 211);
  --bluish: rgb(39, 113, 189);
  --azure: rgb(41, 173, 235);
	--brightRed: rgb(208, 2, 27);

  /* Black */
  --almostBlack: rgb(17, 17, 17);
  --black: rgb(51, 51, 51);
  --black-two: rgb(0, 0, 0);
  --black-two-39: rgba(0, 0, 0, 0.39);
  --black-three: rgb(31, 31, 31);
  --black-three-90: rgba(31, 31, 31, 0.9);
  --black-four: rgb(44, 44, 44);
  --black-five: rgb(34, 34, 34);

  /* White */
  --white: rgb(255, 255, 255);
  --white-two: rgb(249, 249, 249);
  --white-three: rgb(244, 244, 244);
  --white-grey: rgb(102, 102, 102);

  /* Dark */
  --dark: rgb(27, 34, 54);
  --dark-two: rgb(27, 34, 55);
  --dark-grey: rgb(32, 32, 34);
  --dark-grey-blue: rgb(43, 56, 93);
  --dark-grey-blue-0: rgba(43, 56, 93, 0);
  --dark-slate-blue: rgb(19, 52, 86);
  --dark-forest-green: rgb(0, 1, 0);
  --dark-slate-blue-two: rgb(47, 37, 103);

  --very-light-pink: rgb(237, 237, 237);
  --very-light-pink-two: rgb(216, 216, 216);
  --very-light-pink-three: rgb(200, 200, 200);

  --very-light-blue: rgb(230, 244, 251);

  --medium-grey: #666666;
  --brown-grey: rgb(172, 172, 172);
  --brown-grey-40: rgba(172, 172, 172, 0.4);
  --brown-grey-50: rgba(165, 165, 165, 0.5);
  --brown-grey-two: rgb(148, 148, 148);
  --brown-grey-three: rgb(170, 170, 170);

  --brownish-grey: rgb(116, 116, 116);
  --brownish-grey-two: rgb(96, 96, 96);

  --nice-blue: rgb(16, 110, 165);
  --dusk-blue: rgb(31, 80, 125);
  --aqua-blue: rgb(0, 201, 225);
  --bright-cyan: rgb(76, 198, 255); /* + */
  --slate-grey: rgb(81, 81, 83);
  --pale-grey: rgb(242, 245, 248);
  --light-grey: rgb(233, 233, 233);
  --light-blue: rgb(219, 239, 255);
  --lighter-blue: rgb(204, 232, 255);


	/* Color mods, as it doesn't correctly pass imported root variables. */
  --almostBlack-alpha80: rgba(17, 17, 17, 0.8);
  --almostBlack-alpha95: rgba(17, 17, 17, 0.95);
  --brightRed-lightness75: #ffe6e9;
}
:root {
    --global-header-height: 110px;
    --global-padding: 100px;
    --global-padding-top-bottom: 80px;
    --global-padding-small: 40px;
}
@media (max-width: 720px) {
    :root {
        --global-header-height: 76px;
        --global-padding: var(--global-padding-small);
        --global-padding-top-bottom: 40px;
    }
}
@media (max-width: 375px) {
    :root {
        --global-padding: 25px;
    }
}
:root {
    --font-Poppins: 'Roboto Condensed', sans-serif;
    --h1-font-size: 52px;
    --weight-light: 300;
    --weight-regular: 400;
    --weight-medium: 500;
    --weight-semiBold: 600;
    --weight-bold: 700;
}
@media (max-width: 1024px) {
    :root {
        --h1-font-size: 40px;
    }
}
@media (max-width: 720px) {
    :root {
        --h1-font-size: 30px;
    }
}
@media (max-width: 375px) {
    :root {
        --h1-font-size: 24px;
    }
}
dialog {
  position: absolute;
  left: 0; right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  margin: auto;
  border: solid;
  padding: 1em;
  background: white;
  color: black;
  display: block;
}
dialog:not([open]) {
  display: none;
}
dialog + .backdrop {
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  background: rgba(0,0,0,0.1);
}
._dialog_overlay {
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
}
dialog.fixed {
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
}
:root {
    color: var(--almostBlack);
    font-family: var(--font-Poppins);
    scroll-behavior: smooth;
}
body {
    margin: 0;
}
[id=root] {
    overflow: hidden;
}
*,
::after,
::before {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}
.text-center {
    text-align: center;
}
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}

