:root {
    --global-header-height: 110px;
    --global-padding: 100px;
    --global-padding-top-bottom: 80px;
    --global-padding-small: 40px;
}

@media (--mq-width-720) {
    :root {
        --global-header-height: 76px;
        --global-padding: var(--global-padding-small);
        --global-padding-top-bottom: 40px;
    }
}

@media (--mq-width-375) {
    :root {
        --global-padding: 25px;
    }
}
