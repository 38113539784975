:root {
  --cerulean: rgb(0, 144, 211);
  --bluish: rgb(39, 113, 189);
  --azure: rgb(41, 173, 235);
	--brightRed: rgb(208, 2, 27);

  /* Black */
  --almostBlack: rgb(17, 17, 17);
  --black: rgb(51, 51, 51);
  --black-two: rgb(0, 0, 0);
  --black-two-39: rgba(0, 0, 0, 0.39);
  --black-three: rgb(31, 31, 31);
  --black-three-90: rgba(31, 31, 31, 0.9);
  --black-four: rgb(44, 44, 44);
  --black-five: rgb(34, 34, 34);

  /* White */
  --white: rgb(255, 255, 255);
  --white-two: rgb(249, 249, 249);
  --white-three: rgb(244, 244, 244);
  --white-grey: rgb(102, 102, 102);

  /* Dark */
  --dark: rgb(27, 34, 54);
  --dark-two: rgb(27, 34, 55);
  --dark-grey: rgb(32, 32, 34);
  --dark-grey-blue: rgb(43, 56, 93);
  --dark-grey-blue-0: rgba(43, 56, 93, 0);
  --dark-slate-blue: rgb(19, 52, 86);
  --dark-forest-green: rgb(0, 1, 0);
  --dark-slate-blue-two: rgb(47, 37, 103);

  --very-light-pink: rgb(237, 237, 237);
  --very-light-pink-two: rgb(216, 216, 216);
  --very-light-pink-three: rgb(200, 200, 200);

  --very-light-blue: rgb(230, 244, 251);

  --medium-grey: #666666;
  --brown-grey: rgb(172, 172, 172);
  --brown-grey-40: rgba(172, 172, 172, 0.4);
  --brown-grey-50: rgba(165, 165, 165, 0.5);
  --brown-grey-two: rgb(148, 148, 148);
  --brown-grey-three: rgb(170, 170, 170);

  --brownish-grey: rgb(116, 116, 116);
  --brownish-grey-two: rgb(96, 96, 96);

  --nice-blue: rgb(16, 110, 165);
  --dusk-blue: rgb(31, 80, 125);
  --aqua-blue: rgb(0, 201, 225);
  --bright-cyan: rgb(76, 198, 255); /* + */
  --slate-grey: rgb(81, 81, 83);
  --pale-grey: rgb(242, 245, 248);
  --light-grey: rgb(233, 233, 233);
  --light-blue: rgb(219, 239, 255);
  --lighter-blue: rgb(204, 232, 255);


	/* Color mods, as it doesn't correctly pass imported root variables. */
  --almostBlack-alpha80: color-mod(var(--almostBlack) alpha(80%));
  --almostBlack-alpha95: color-mod(var(--almostBlack) alpha(95%));
  --brightRed-lightness75: color-mod(var(--brightRed) lightness(95%));
}
